/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-var */
/* eslint-disable no-empty */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { acimaPoGlobalStyles } from '../../jsStyles/AcimaGlobalStyles'
import { ReactComponent as Alerticon } from "../../assets/images/no-records-found.svg";
import { ReactComponent as NoRecordsFound } from '../../assets/images/No-records.svg'
import { ReactComponent as AcimaRightArrow } from '../../assets/images/acimaRightArrow.svg';
import { ReactComponent as AcimaDownArrow } from '../../assets/images/acimaDownArrow.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search-btn.svg';
import moment from 'moment';
import { GetTransactionType, getPoSummary, getDetails } from '../../api/user'
import {
    Grid,
    Typography,
    RACButton,
    RACSelect,
    RACRadio,
    RACModalCard,
    RACTextbox,
    RACCheckBox,
    CircularProgress,
    RACDatePicker,
    Card,
    Modal,
    CardContent,
    RACTable,
    RACTableCell,
    RACTableRow
} from '@rentacenter/racstrap';

export default function PoSummary() {
    const history = useHistory()
    const modalCardRadius = '25px !important';
    const classes = acimaPoGlobalStyles();
    const [searchResult, setsearchResult] = useState<any>(false)
    const [dropDownloading, setdropDownloading] = useState(true)
    const [noRecords, setnoRecords] = useState(false)
    const [fetchDataLoader, setfetchDataLoader] = useState(false);
    const [hasMore, sethasMore] = useState(true);
    const [pageNumber, setpageNumber] = useState(1);
    const [transferRequestTableLoader, settransferRequestTableLoader] = useState(false)
    const [showCustomerAgrIndex, setshowCustomerAgrIndex] =
        useState<any>(undefined);
    const [poStatus, setPoStatus] = useState([
        { label: '', value: '' },
    ]);
    const [poDeliveryStatus, setpoDeliveryStatus] = useState([{
        label: '', value: ''
    }])
    const searchObj = {
        poNumber: '',
        lastName: '',
        firstName: '',
        fromDate: '',
        toDate: '',
        poSelectedOption: '',
        deliverySelectedOption: '',
        fromDateErrorMsg: '',
        todateErrorMsg: ''
    };
    useState<any>(undefined);
    const [searchData, setSearchData] = useState<any>(searchObj);
    const [poSummary, setPoSummary] = useState<any>([])
    const [poDetails, setPoDetails] = useState<any>([])
    const [hiddenLoader, sethiddenLoader] = useState(false);
    const [errorMessagePopupOpen, seterrorMessagePopupOpen] = useState(false);
    const [manageAgrErrMessage, setmanageAgrErrMessage] = useState('');
    const [somethingWentWrongOpen, setSomethingWentWrongOpen] = useState(false);

    useEffect(() => {
        getPoStatus()
        searchClick()
    }, [])
    const POReceiveAccordianHeadings = [
        { heading: '' },
        { heading: 'PO#' },
        { heading: 'PO Created Date' },
        { heading: 'Vendor Name' },
        { heading: 'ETA' },
        { heading: 'Last Name' },
        { heading: 'First Name' },
        { heading: 'PO Status' },
        { heading: 'Delivery Status' }

    ]
    const POReceiveAccordianItemTableHeadings = [
        { heading: 'Item #' },
        { heading: 'Model #' },
        { heading: 'Description' },
        { heading: 'Brand' },
        { heading: 'Cost' },
        { heading: 'Status' },
    ]
    const getPoStatus = async () => {

        try {
            let poStatusRes: any = ''
            let poDeliveryStatusRes: any = ''
            await Promise.all([
                GetTransactionType("Lto_po_status_type"),
                GetTransactionType("Lto_po_delivery_status_type")
            ]).then((resp) => {
                [poStatusRes, poDeliveryStatusRes] = resp
            })
            if (poStatusRes?.status == 200 && poDeliveryStatusRes?.status == 200) {
                setdropDownloading(false)
                poStatusRes = poStatusRes?.data?.referenceDetails;
                poDeliveryStatusRes = poDeliveryStatusRes?.data?.referenceDetails
                const removechargeOff = poStatusRes.filter(detail => detail.referenceCode !== "CHGOF" && detail.referenceCode !== "APR" );

                const states = removechargeOff?.map((obj: any) => {
                    return { label: obj.description, value: obj.referenceCode }
                })
                states.splice(0, 0, {
                    value: '',
                    label: 'All',
                });
                setPoStatus(states)
                const descending = poDeliveryStatusRes.sort((a, b) => parseInt(b.displaySeq) - parseInt(a.displaySeq));
                const poDelivery = descending?.map((obj: any) => {
                    return { label: obj.description, value: obj.referenceCode }
                })
                console.log("poDelivery", poDelivery)
                poDelivery.splice(0, 0, {
                    value: '',
                    label: 'All',
                });
                setpoDeliveryStatus(poDelivery)
            }
            else if (poStatusRes?.status == 400) {
                seterrorMessagePopupOpen(true);
                setmanageAgrErrMessage(poStatusRes?.data?.errors[0]?.error);
            }
        }
        catch {
        }

    }
    const showAgreement = async (i: any, poNumber: any) => {
        let res: any = "";
        setPoDetails([])
        if (i != showCustomerAgrIndex) {
            setshowCustomerAgrIndex(i == showCustomerAgrIndex ? undefined : i);
            res = await getDetails(poNumber);
        }
        if (!res) {
            setshowCustomerAgrIndex(undefined);
        } else {
            if (i != showCustomerAgrIndex) {
                if (res.status == 200 && res?.data) {
                    if (res?.data?.scheduledInformation) {
                        let scheduledInformationArray: any = []
                        res?.data?.scheduledInformation.map((obj: any) => {
                            obj.itemInformation.map((eachItem: any) => {
                                scheduledInformationArray.push(eachItem)
                            })
                        })
                        setPoDetails([...scheduledInformationArray, ...res?.data?.itemInformation])
                    }
                    else setPoDetails(res?.data?.itemInformation)
                } else {
                    setPoDetails(undefined)

                }
            }
        };
    }
    const renderAgreementTableContent = () => (
        renderAgreementTableContentFilled()
    );
    const renderAgreementTableContentFilled = () => {
        return (
            <React.Fragment>

                {poDetails?.map((obj: any, index: any) => {

                    return (
                        <RACTableRow>
                            <RACTableCell

                                style={{ "marginTop": "1px", "left": "9px", "color": 'gray' }}
                            >
                                {obj.poDetailReceivedId ? obj.poDetailReceivedId : '-'}
                            </RACTableCell>
                            <RACTableCell style={{ color: 'gray' }} >
                                {obj.model ? obj.model : '-'}
                            </RACTableCell>
                            <RACTableCell style={{ color: 'gray' }}>
                                {obj.description ? obj.description : '-'}
                            </RACTableCell>
                            <RACTableCell style={{ color: 'gray' }}>
                                {obj.brand ? obj.brand : '-'}
                            </RACTableCell>
                            <RACTableCell style={{ color: 'gray' }}>
                                {obj.itemCost ? obj.itemCost : '-'}
                            </RACTableCell>
                            <RACTableCell style={{ color: 'gray' }}>
                                {obj.itemCost ? obj.itemStatus : '-'}
                            </RACTableCell>
                        </RACTableRow>
                    );
                })}
            </React.Fragment>
        );
    };
    const agreementTable = (i: any) => {
        return showCustomerAgrIndex == i ? (
            <RACTableRow
                className={`{${classes.customerAccordianopen} ${classes.customerTablecellbgcolor}`}
            >
                <td
                    colSpan={15}
                    className={`${classes.customerHiddenrow} ${classes.spacerP0}`}
                >
                    <div id="row-1" >
                        {poDetails.length == 0 ? (
                            <div className={classes.customerNorecordsAgr}>
                                <CircularProgress />
                            </div>
                        ) : poDetails == undefined ? (
                            <div className={classes.customerTextcenter}>
                                <div
                                    className={`${classes.customerRow} ${classes.customerJustifycontentcenter}`}
                                >
                                    <div className={classes.customerColmd}>
                                        <Alerticon></Alerticon>
                                        <p className={classes.spacerMT3}>
                                            No Po information found
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : poDetails !== undefined &&
                            poDetails !== null &&
                            poDetails.length > 0 ? (
                            <RACTable
                                renderTableHead={renderItemTableHeadFn}
                                renderTableContent={renderAgreementTableContent}
                                className={`${classes.agreementGrid}`}
                            />
                        ) : (
                            <div className={classes.customerTextcenter}>
                                <div
                                    className={
                                        (classes.customerRow, classes.customerJustifycontentcenter)
                                    }
                                >
                                    <div className={classes.customerColmd}>
                                        <Alerticon></Alerticon>
                                        <p className={classes.spacerMT3}>
                                            No Po information found
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </td>
            </RACTableRow>
        ) : null;
    };
    const renderTableHeadFn = () => (
        <>
            {POReceiveAccordianHeadings.map((obj: any) => {
                return <RACTableCell>{obj.heading}</RACTableCell>
            })}
        </>
    )
    const renderItemTableHeadFn = () => (
        <>
            {POReceiveAccordianItemTableHeadings.map((obj: any) => {
                return <RACTableCell>{obj.heading}</RACTableCell>
            })}
        </>
    )
    const dateFormatFn = (date: any) => {
        if (!date) {
            return '-'
        }
        return moment(date).format('MM/DD/YYYY')
    }
    const renderTableContentFn = () => (
        <>
            {
                noRecords ?
                    <>
                        <RACTableRow>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell style={{ "float": "right" }}>
                                {transferRequestTableLoader ?
                                    <>
                                        <Grid style={{ "marginTop": "50px" }}>
                                            <CircularProgress />
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                                            <NoRecordsFound />
                                            <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                                                No records found
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                            </RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                        </RACTableRow>
                    </>
                    : <>
                        {poSummary?.map((obj: any, i: any) => {
                            return (
                                <React.Fragment>
                                    <RACTableRow>
                                        <RACTableCell
                                            data-bs-toggle="collapse"
                                            onClick={() => showAgreement(i, obj.purchaseOrderNumber)}
                                            data-bs-target="#row-1"
                                            aria-expanded={showCustomerAgrIndex == i ? true : false}
                                            className={`${classes.accordianWidth}`}
                                            style={{ cursor: 'pointer', }}
                                        >
                                            {showCustomerAgrIndex !== i ? (

                                                <AcimaRightArrow />
                                            ) : (
                                                <AcimaDownArrow />
                                            )}

                                        </RACTableCell>
                                        <RACTableCell

                                            style={{ cursor: 'pointer', fontFamily: 'OpenSans-bold', "marginTop": "1px", "left": "9px", color: '#2179FE' }}
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/acimapo/poreceive/details/${obj.purchaseOrderNumber}`,
                                                });
                                            }}                                        >
                                            {obj.purchaseOrderNumber ? obj.purchaseOrderNumber : '-'}
                                        </RACTableCell>
                                        <RACTableCell style={{ color: 'gray' }} title={obj.agreementDescription}>
                                            {dateFormatFn(obj.createdDate)}
                                        </RACTableCell>
                                        <RACTableCell style={{ color: 'gray' }}>{obj.vendorName ? obj.vendorName : '-'}</RACTableCell>
                                        <RACTableCell style={{ color: 'gray' }}>
                                            {dateFormatFn(obj.estimatedDeliveryDate)}
                                        </RACTableCell>
                                        <RACTableCell style={{ color: 'gray' }}>
                                            {obj.lastName ? obj.lastName : '-'}
                                        </RACTableCell>
                                        <RACTableCell style={{ color: 'gray' }}>
                                            {obj.firstName ? obj.firstName : '-'}
                                        </RACTableCell>
                                        <RACTableCell style={{ color: 'gray' }}>
                                            {obj.poStatus ? obj.poStatus.toLowerCase() == "pending" || obj.poStatus.toLowerCase() == "approved-released to store" ? "Pending" : obj.poStatus  : '-'}
                                        </RACTableCell>
                                        <RACTableCell style={{ color: 'gray' }}>
                                            {obj.deliveryStatus ? obj.deliveryStatus : '-'}
                                        </RACTableCell>
                                    </RACTableRow>
                                    {agreementTable(i)}
                                </React.Fragment>

                            );
                        })}
                    </>
            }
        </>
    )



    const searchClick = async () => {
        if ((searchData.fromDate && searchData.toDate) || (!searchData.fromDate && !searchData.toDate)) {
            setSearchData({ ...searchData, fromDateErrorMsg: '', todateErrorMsg: '' });
            setsearchResult(true)
            setnoRecords(true)
            settransferRequestTableLoader(true)
            const payload = {
                storeNumber: window.sessionStorage.getItem('storeNumber'),
                firstName: searchData.firstName ? searchData.firstName : null,
                lastName: searchData.lastName ? searchData.lastName : null,
                purchaseOrderNumber: searchData.poNumber ? searchData.poNumber : null,
                toDate: searchData.toDate ? searchData.toDate : null,
                fromDate: searchData.fromDate ? searchData.fromDate : null,
                poStatus: searchData.poSelectedOption ? searchData.poSelectedOption : null,
                deliveryStatus: searchData.deliverySelectedOption ? searchData.deliverySelectedOption : null

            }

            const getPoSummaryRes = await getPoSummary(payload, 1)
            setnoRecords(false)
            settransferRequestTableLoader(false)
            if (getPoSummaryRes?.status == 200 && getPoSummaryRes?.data?.ltoPurchaseOrderSummary.length > 0) {
                setPoSummary(getPoSummaryRes?.data?.ltoPurchaseOrderSummary)
            }
            else if (getPoSummaryRes?.status == 200 && getPoSummaryRes?.data?.ltoPurchaseOrderSummary.length == 0) {
                setnoRecords(true)
            }
            else if (getPoSummaryRes?.status == 400) {
                seterrorMessagePopupOpen(true);
                setmanageAgrErrMessage(getPoSummaryRes?.data?.errors[0]?.error);
                setsearchResult(false)
            }
            else {
                setSomethingWentWrongOpen(true)
            }
        }
        else {
            if (!searchData.fromDate && searchData.toDate) {
                setSearchData({ ...searchData, fromDateErrorMsg: 'This field is required' })
            }
            else if (searchData.fromDate && !searchData.toDate) {
                setSearchData({ ...searchData, todateErrorMsg: 'This field is required' })
            }
        }

    }


    const fetchNextData = async () => {
        sethasMore(true);
        setfetchDataLoader(true);
        setpageNumber((prev) => prev + 1);
        const payload = {
            storeNumber: window.sessionStorage.getItem('storeNumber'),
            firstName: searchData.firstName ? searchData.firstName : null,
            lastName: searchData.lastName ? searchData.lastName : null,
            purchaseOrderNumber: searchData.poNumber ? searchData.poNumber : null,
            toDate: searchData.toDate ? searchData.toDate : null,
            fromDate: searchData.fromDate ? searchData.fromDate : null,
            poStatus: searchData.poSelectedOption ? searchData.poSelectedOption : null,
            deliveryStatus: searchData.deliverySelectedOption ? searchData.deliverySelectedOption : null

        }
        const fetchNextDataRes = await getPoSummary(
            payload,
            pageNumber + 1
        );
        if (fetchNextDataRes?.status == 200) {
            setfetchDataLoader(false);
            const fetchNexyDataArray = fetchNextDataRes?.data?.ltoPurchaseOrderSummary;
            setPoSummary([
                ...poSummary,
                ...fetchNexyDataArray,
            ]);
            if (fetchNextDataRes?.data?.ltoPurchaseOrderSummary?.length !== 10) {
                sethasMore(false);
            }
        } else if (fetchNextDataRes?.status == 400) {
            seterrorMessagePopupOpen(true);
            setmanageAgrErrMessage(fetchNextDataRes?.data?.errors[0]?.error);
        } else {
            setSomethingWentWrongOpen(true);
        }
    };
    const somethingWentWrongPopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    <Alerticon></Alerticon>

                    <Typography className={classes.formLabel}>
                        Something went wrong!
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSomethingWentWrongOpen(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </div>
        );
    };
    const errorMessagePopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    <Alerticon></Alerticon>
                    <Typography className={classes.formLabel}>
                        {manageAgrErrMessage}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            seterrorMessagePopupOpen(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </div>
        );
    };

    const handleClear = async () => {
        setSearchData(searchObj);
        const payload = {
            storeNumber: window.sessionStorage.getItem('storeNumber'),
            firstName: null,
            lastName: null,
            purchaseOrderNumber: null,
            toDate: null,
            fromDate: null,
            poStatus: null,
            deliveryStatus: null
        }
        setsearchResult(true)
        setnoRecords(true)
        settransferRequestTableLoader(true)
        const getPoSummaryRes = await getPoSummary(payload, 1)
        setnoRecords(false)
        settransferRequestTableLoader(false)
        if (getPoSummaryRes?.status == 200 && getPoSummaryRes?.data?.ltoPurchaseOrderSummary.length > 0) {
            setPoSummary(getPoSummaryRes?.data?.ltoPurchaseOrderSummary)
        }
        else if (getPoSummaryRes?.status == 200 && getPoSummaryRes?.data?.ltoPurchaseOrderSummary.length == 0) {
            setnoRecords(true)
        }
        else if (getPoSummaryRes?.status == 400) {
            seterrorMessagePopupOpen(true);
            setmanageAgrErrMessage(getPoSummaryRes?.data?.errors[0]?.error);
            setsearchResult(false)
        }
        else {
            setSomethingWentWrongOpen(true)
        }
    }

    // Click Enter In Keyboard
    const EnterOnClick = (e: any) => {
        if (
            e.key == 'Enter' &&
            (searchData.poNumber !== '' ||
                searchData.firstName !== '' ||
                searchData.lastName !== '' ||
                searchData.lastName !== '' ||
                searchData.fromDate !== '' ||
                searchData.toDate !== '')
        ) {
            searchClick();
        }
    };

    const handleOnchange = async (value?: any, type?: any) => {
        if (value.type == 'change' && !value.target.value && !searchData.firstName && !searchData.lastName && !searchData.fromDate && !searchData.toDate) {
            handleClear()
        }
    }
    const handleFirstNameOnchange = async (value?: any, type?: any) => {
        console.log("valuevalue", value.type);

        if (value.type == 'change' && !value.target.value && !searchData.lastName && !searchData.fromDate && !searchData.toDate && !searchData.poNumber) {
            handleClear()
        }
    }
    const handleLastNameOnchange = async (event?: any, type?: string) => {
        if (type === 'LastNameInput') {
            if (event.type == 'change' && !event.target.value && !searchData.firstName && !searchData.fromDate && !searchData.toDate && !searchData.poNumber) {
                handleClear();
            }
        }
    }

    return (
        <div>
            {hiddenLoader === true ? (
                <Grid
                    style={{
                        position: 'fixed',
                        background: '#f7f5f5',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        opacity: 0.6,
                        zIndex: 2000,
                        textAlign: 'center',
                        margin: '0px 0px',
                    }}
                >
                    <Grid
                        style={{
                            display: 'block',
                            position: 'fixed',
                            zIndex: 9999999,
                            top: '40%',
                            right: '50%',
                        }}
                    >
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : null}
            <RACModalCard
                isOpen={somethingWentWrongOpen}
                closeIcon={false}
                maxWidth="xs"
                borderRadius={modalCardRadius}
            >
                {somethingWentWrongPopup()}
            </RACModalCard>
            <RACModalCard
                isOpen={errorMessagePopupOpen}
                closeIcon={false}
                maxWidth="xs"
                borderRadius={modalCardRadius}
            >
                {errorMessagePopup()}
            </RACModalCard>
            <Grid container className={`${classes.mb3}`}>
                <Grid item md={12}>
                    <Typography
                        variant="h6"
                        className={`${classes.floatLeft} ${classes.mt5}  ${classes.title}`}

                    >
                        Acima Purchase Order Receiving
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container spacing={2} style={{ width: '100%', display: 'block' }}>
                                <Grid item xs={12} style={{ width: '20%', float: 'left' }}>
                                    <RACTextbox
                                        type="text"
                                        name="po"
                                        inputlabel="PO#"
                                        value={searchData.poNumber}
                                        OnChange={(e: any) => {
                                            setSearchData({
                                                ...searchData,
                                                poNumber: e.target.value.trimStart(),
                                            });
                                            handleOnchange(e)
                                        }
                                        }
                                        OnKeydown={(e) => EnterOnClick(e)}

                                    />
                                </Grid>
                                <Grid item style={{ width: '20%', float: 'left' }} xs={12}>
                                    <RACTextbox
                                        type="text"
                                        name="Lname"
                                        value={searchData.lastName}
                                        inputlabel="Last Name"
                                        OnChange={(e: any) => {
                                            const onlyLetters = /^[A-Za-z]+$/;
                                            if (!onlyLetters.test(e.target.value)) {
                                                e.target.value = e.target.value.replace(/[^A-Za-z]/g, "");
                                            }
                                            setSearchData({
                                                ...searchData,
                                                lastName: e.target.value,
                                            });
                                            handleLastNameOnchange(e, 'LastNameInput');
                                        }}
                                        OnKeydown={(e) => EnterOnClick(e)}

                                    />
                                </Grid>
                                <Grid item style={{ width: '20%', float: 'left' }} xs={12}>
                                    <RACTextbox
                                        type="text"
                                        name="Fname"
                                        inputlabel="First Name"
                                        value={searchData.firstName}
                                        OnChange={(e: any) => {
                                            const onlyLetters = /^[A-Za-z]+$/;
                                            if (!onlyLetters.test(e.target.value)) {
                                                e.target.value = e.target.value.replace(/[^A-Za-z]/g, "");
                                            }
                                            setSearchData({
                                                ...searchData,
                                                firstName: e.target.value,
                                            });
                                            handleFirstNameOnchange(e)

                                        }}
                                        OnKeydown={(e) => EnterOnClick(e)}

                                    />
                                </Grid>
                                <Grid
                                    item
                                    style={{ width: '20%', float: 'left' }}
                                    xs={12}
                                >
                                    <RACDatePicker
                                        id="FDate"
                                        label="From Date"
                                        value={searchData.fromDate}
                                        name="fromDate"
                                        onChange={(e: any) => setSearchData({
                                            ...searchData,
                                            fromDate: e,
                                        })}
                                        inputProps={{
                                            min: moment().subtract(118, "years").format("MM-DD-YYYY"),
                                            max: searchData.toDate
                                        }}
                                        onKeyDown={(e) => EnterOnClick(e)}

                                    />
                                    <label style={{ color: 'red' }}>{searchData.fromDateErrorMsg}</label>
                                </Grid>
                                <Grid
                                    item
                                    style={{ width: '20%', float: 'left' }}
                                    xs={12}
                                >
                                    <RACDatePicker
                                        id="TDate"
                                        label="To Date"
                                        value={searchData.toDate}
                                        name="toDate"
                                        onChange={(e: any) => setSearchData({
                                            ...searchData,
                                            toDate: e,
                                        })}
                                        inputProps={{
                                            min: searchData.fromDate,
                                            max: moment().subtract(118, "years").format("MM-DD-YYYY"),
                                        }}
                                        onKeyDown={(e) => EnterOnClick(e)}

                                    />
                                    <label style={{ color: 'red' }}>{searchData.todateErrorMsg}</label>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ width: '100% !important' }}>
                                <Grid style={{ width: '20%', float: 'left' }} className={`${classes.mb3} ${classes.px2}`}>
                                    <RACSelect
                                        inputLabel={'Po Status'}
                                        loading={dropDownloading}
                                        defaultValue={searchData.poSelectedOption}
                                        options={poStatus}
                                        name='poStatus'
                                        onChange={(e: any) => setSearchData({
                                            ...searchData,
                                            poSelectedOption: e.target.value,
                                        })}
                                    />
                                </Grid>
                                <Grid style={{ width: '20%', float: 'left' }} className={`${classes.mb3} ${classes.px2}`}>
                                    <RACSelect
                                        inputLabel={'Delivery Status'}
                                        loading={dropDownloading}
                                        defaultValue={searchData.deliverySelectedOption}
                                        options={poDeliveryStatus}
                                        name='deliveryStatus'
                                        onChange={(e: any) => setSearchData({
                                            ...searchData,
                                            deliverySelectedOption: e.target.value,
                                        })}
                                    />
                                </Grid>
                                <Grid style={{ width: '40%', float: 'left' }}>
                                </Grid>
                                <Grid
                                    style={{ width: '20%', float: 'left', marginTop: '15px', display: 'flex', alignItems: 'center' }}
                                >
                                    <RACButton
                                        // style={
                                        //     { padding: '10px 36px' }
                                        // }
                                        variant="outlined"
                                        color='primary'
                                        onClick={() => { handleClear() }}
                                        disabled={searchData.firstName || searchData.lastName || searchData.poNumber || searchData.fromDate || searchData.toDate || searchData.poSelectedOption || searchData.deliverySelectedOption ? false : true}
                                    >
                                        Clear
                                    </RACButton>
                                    <RACButton
                                        // style={
                                        //     { padding: '10px 38px', "marginLeft": "25px" }
                                        // }
                                        startIcon={<SearchIcon style={{ "marginTop": "0.2rem" }} />}
                                        style={{ marginLeft: '30px' }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            searchClick()
                                        }}
                                        disabled={searchData.firstName || searchData.lastName || searchData.poNumber || searchData.fromDate || searchData.toDate || searchData.poSelectedOption || searchData.deliverySelectedOption ? false : true}
                                    >
                                        Search
                                    </RACButton>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {
                searchResult ? <>
                    <Grid className={`${classes.mb3}`}>
                        <Card style={{
                            borderRadius: '15px',
                            height: '450px',
                            width: '100%',
                            marginTop: '25px',
                        }}>
                            <CardContent>
                                <Grid className={classes.fixTableHeadAgrTrsRec}>
                                    {poSummary?.length > 9 ? (
                                        <InfiniteScroll
                                            next={fetchNextData}
                                            dataLength={poSummary?.length}
                                            hasMore={hasMore}
                                            height={390}
                                            loader={
                                                fetchDataLoader ? (
                                                    <Grid className={classes.GridLoader}>
                                                        <CircularProgress></CircularProgress>
                                                    </Grid>
                                                ) : null
                                            }
                                        >
                                            <RACTable
                                                renderTableHead={renderTableHeadFn}
                                                renderTableContent={renderTableContentFn}
                                            />
                                        </InfiniteScroll>
                                    ) : (
                                        <RACTable
                                            renderTableHead={renderTableHeadFn}
                                            renderTableContent={renderTableContentFn}
                                        />
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </>
                    : null}
        </div>
    )
}