/* eslint-disable prettier/prettier */
import { client, clientCall } from './client';
import { appConfig } from '../config/app-config';
import { CancelToken } from 'axios';
import { ProductPermissions } from '../constants/constants';
import { User } from '../types/types';
export const getCurrentUser = (): Promise<User> =>
  client('users/current', { method: 'GET' }, appConfig.apiUrls.container,true);
export const getUserPreferences = (productType: string) =>
  client(
    `users/current/preferences/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container
  );
export const getUserPermissions = (
  productType: string
): Promise<ProductPermissions[]> =>
  client(
    `users/current/permissions/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container
  );
export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,
    {
      method: 'PATCH',
      body: [payload],
      cancelToken
    },
    appConfig.apiUrls.container
  );
export const getUserStores = () =>
  client(
    'users/current/stores?q=permissions[:includes]=RACPAD_DAP',
    { method: 'GET' },
    appConfig.apiUrls.container
  );
export const getPoSummary = async (Payload: any, offset: any) => {
  return await clientCall(
    `summary?offset=${offset}`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    true
  );
};
export const getDetails = async (Payload: any) => {
  return await clientCall(
    `details/${Payload}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    true
  );
};
export const GetTransactionType = async (param: string) => {
  try {
    return await clientCall(
      `reference?referenceName=${param}`,
      { method: 'GET' },
      appConfig.apiUrls.cashMgmt,
      true
    );
  } catch (e: any) {
    return null;
  }
};
export const getLtoPurchaseOrderDetail = async (Payload: any) => {
  try {
    return await clientCall(
      `details/${Payload?.poNumber}`,
      { method: 'GET' },
      appConfig.apiUrls.micro,
      true
    );
  } catch (error) {
    return null;
  }
};
export const postPoReceive = async (Payload: any) => {
  try {
    return await clientCall(
      `po/receive`,
      { method: 'POST', body: Payload },
      appConfig.apiUrls.micro,
      true
    );
  } catch (error) {
    return null;
  }
};
export const GetTimeSlots = async (bodyobj: any) => {
  return await clientCall(
    `agreement/timeslots`,
    { method: 'POST', body: bodyobj },
    appConfig.apiUrls.agreement,
    true
  );
};
export const acimascheduleInformation = async (bodyobj: any) => {
  return await clientCall(
    `agreement/delivery`,
    { method: 'POST', body: bodyobj },
    appConfig.apiUrls.agreement,
    true
  );
};
export const managePoAcimaFn = async (bodyobj: any) => {
  return await clientCall(
    `manage/${bodyobj.purchaseOrderNumber}`,
    { method: 'POST', body: bodyobj },
    appConfig.apiUrls.micro,
    true
  );
};
// export const getStatesByReference = async (req: string) => {
//   return await clientCall(
//     `reference?referenceName=${req}`,
//     { method: "GET" },
//     `https://jfa3r9dgna.execute-api.us-east-1.amazonaws.com/api`,
//     true
//   )
// }
export const GenerateReceipt = async (bodyobj: any) => {
  return await clientCall(
    `agreement/generatereceipt`,
    { method: 'POST', body: bodyobj },
    appConfig.apiUrls.agreement,
    true
  );
};
export const updateStatusCall = async (bodyobj: any) => {
  return await clientCall(
    `agreement/delivery/status/update`,
    { method: 'PUT', body: bodyobj },
    appConfig.apiUrls.agreement,
    true
  );
};
export const postPoReverse = async (Payload: any) => {
  return await clientCall(
    `po/reverse`,
    { method: 'Post', body: Payload },
    appConfig.apiUrls.micro,
    true
  );
}
export const updateEventStatus = async (Payload: any) => {
  return await clientCall(
    `appointment/action`,
    { method: 'Post', body: Payload },
    appConfig.apiUrls.storeMgnt,
    true
  );
}

export const GetStatsStatus = async (Payload: any) => {
  return await clientCall(
    `store-details`,
    { method: "Post", body: Payload },
     appConfig.apiUrls.storeMgnt,
    true
  );
};

export const sendEmailOrText = async (bodyobj: any) => {
  return await clientCall(
    `agreement/document/print`,
    { method: 'POST', body: bodyobj },
    appConfig.apiUrls.agreement,
    true
  );
};