/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-var */
/* eslint-disable no-empty */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { CircularProgress, Grid } from "@rentacenter/racstrap"

import React, { useContext } from "react";

import { PoDetailsContext } from "./context/PoDetailsContext";

export const HiddenLoader = () => {

    const { isHiddenLoader, setIsHiddenLoader } = useContext(PoDetailsContext);

    return isHiddenLoader ? (
        <Grid
            style={{

                position: 'fixed',

                background: '#f7f5f5',

                width: '100%',

                height: '100%',

                top: 0,

                left: 0,

                opacity: 0.6,

                zIndex: 2000,

                textAlign: 'center',

                margin: '0px 0px',

            }}

        >
            <Grid

                style={{

                    display: 'block',

                    position: 'fixed',

                    zIndex: 9999999,

                    top: '40%',

                    right: '50%',

                }}

            >

                <CircularProgress />

            </Grid>

        </Grid>

    ) : null

}