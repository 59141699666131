/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { List, ListItem } from "@rentacenter/racstrap"
// import { takePaymentStyles } from "../../../stylesJS/makeStyles";
import { useHistory } from "react-router-dom";
import React, { useContext } from "react";
import { PoDetailsContext } from "./context/PoDetailsContext";
import { takePaymentStyles } from "./PoDetailStyles/makeStyles";

export const PoDetailsBreadCrum = () => {

    /**CSS Imports starts here */
    const takepaymentclasses = takePaymentStyles();
    /*CSS import ends here */

    
    /**Local varables starts here */
    const history = useHistory();

    /**useContext data stats here */
    const { data, setData } = useContext(PoDetailsContext);

    return (
        <List className={`${takepaymentclasses.breadcrumb} ${takepaymentclasses.py1}`}>
            <ListItem className={takepaymentclasses.breadcrumbItem}>
                <a
                    className={takepaymentclasses.breadcrumbInActive}
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(`/dashboard`)}
                >
                    Dashboard
                </a>
            </ListItem>
            <ListItem
                className={`${takepaymentclasses.breadcrumbItem1} ${takepaymentclasses.breadcrumbArrow} ${takepaymentclasses.breadcrumbInActive}`}
            >
                <a
                    className={takepaymentclasses.breadcrumbInActive}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                        history.push(`/acimapo/poreceive`)
                    }
                >
                    Acima PO Recieving
                </a>
            </ListItem>
            <ListItem className={`${takepaymentclasses.breadcrumbItemActive} ${takepaymentclasses.breadcrumbArrow}`}>
                <a style={{ cursor: "pointer" }}>{data?.customerName}</a>
            </ListItem>
        </List>
    )
}