/* eslint-disable */
import { Dispatch } from 'react';

import {
  getUserAction,
  getUserSuccessAction,
  getUserErrorAction,
  UserAction,
} from './user-actions';
import { getCurrentUser } from '../../api/user';
export interface UserThunks {
  getCurrentUser(): Promise<void>;
}

// const fetchPermissions = async (
//   dispatch: Dispatch<UserAction>
// ): Promise<ProductPermissions[]> => {
//   try {
//     return await getUserPermissions(ProductType);
//   } catch (error) {
//     dispatch(getPermissionsErrorAction(error));
//     return [];
//   }
// };

 

export const getCurrentUserThunk =
  (dispatch: Dispatch<UserAction>) => async () => {
    try {
      dispatch(getUserAction());
      

      const userData = await getCurrentUser();
      // const userData = {
      //   ...(await getCurrentUser()),
      //   permissions: await fetchPermissions(dispatch),
      // };
      console.log(userData, "the console in the user-thunks prints userData");
      
 
      dispatch(getUserSuccessAction(userData));
    } catch (error) {
      dispatch(getUserErrorAction(error));
    }
  };
