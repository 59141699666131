/* eslint-disable */
import React from 'react';
import { Route } from 'react-router-dom';

import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
// import { PermissionsProvider } from '../context/permission/PermissionsProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
import PoSummary from '../components/PoSummary/AcimaPoSummary';
import { PoDetails } from '../components/PoDetails/PoDetailsMain';
export const routesTestId = 'routesTestId';

export const Routes = () => {
  console.log('log from route');
  return (
    <UserProvider>
      {/* <PermissionsProvider> */}
      <UserFetcher />
      <div data-testid={routesTestId}>
        <Route exact path={AppRoute.poReceiveLandingPage} >
          <PoSummary />
        </Route>
        <Route exact path={AppRoute.pendingPo}>
          <PoDetails />
        </Route>
      </div>
      {/* </PermissionsProvider> */}
    </UserProvider>
  );
};
