/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import React from "react"
import { CustomerDetails } from './PoDetailsCustomerDetails';
import { PoDetailsProvider } from './context/PoDetailsContext';
import { PendingInformation } from "./PoDetailsPendingInformation"
import { PoDetailsFooter } from "./PoDetailsFooter"
import { ReceivedImformation } from "./PoDetailsReceivedIInformation"
import { Grid } from "@rentacenter/racstrap"
import ScheduleComponent from "./poDetailsScheduler"
import { PoDetailsLoader } from "./PoDetailsLoader";
import { HiddenLoader } from "./HiddenLoader";
import PoSchedulePopUp from "./PoSchedulePopUp";
import ScheduleCard from "./PoSchedulerCard";
import { DeliveryConfirm } from "./SigninAndDelivery";
import { ReverseInformation } from "./PoDetailsReverseInformation";
import { PrintInformation } from "./PoDetailsPrintInformation";

export const PoDetails = () => {
  return (
    <PoDetailsProvider>
      <PoDetailsLoader />
      <div>
        {/**Header and customer details part starts here */}
        <Grid style={{ width: "100%", marginBottom: "10%" }}>
          <HiddenLoader />
          <CustomerDetails />
          {/**Header and customer details part ends here */}
          {/**Pending Information card starts here */}
          <PendingInformation />
          {/**Pending information card ends here */}
          {/**Received part starts here */}
          <ReceivedImformation />
          {/**Received part ends here */}

          {/**Reverse information starts here */}
          <ReverseInformation />
          {/**Reverse information ends here */}

          {/**Schedule cards starts here */}
          {/* <ScheduleComponent /> */}
          <PoSchedulePopUp />
          <ScheduleCard />
          <DeliveryConfirm />

          <PrintInformation/>
          {/**Schedule part ends here */}
        </Grid>
        {/**Footer parts starts here */}
        <PoDetailsFooter />
        {/**Footer parts ends here */}
      </div>
    </PoDetailsProvider>
  )
}