/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import {
  RACButton,
  Grid,
  RACModalCard,
  RACRadio,
  Typography,
  RACTextbox,
  RACSelect,
  RACDatePicker,
  CircularProgress,
  RACTooltip,
} from "@rentacenter/racstrap";
import { agreementGlobalStyles } from "./PoDetailStyles/agreementGlobalStyles";
import { schedulePopupStyles } from "./PoDetailStyles/schedulepopstyles";
import { PoDetailsContext } from "./context/PoDetailsContext";
import moment from "moment";
// import { managePoAcimaFn, GetTimeSlots, acimascheduleInformation } from "../../api/user2";
import { managePoAcimaFn, GetTimeSlots, acimascheduleInformation, GetTransactionType } from "../../api/user";
let BindtimeslotDate = "";
export default function PoSchedulePopUp() {
  let fieldtypeNumber = new RegExp("^[0-9 ]*$");

  const { somethingWentWrongOpen, setSomethingWentWrongOpen, data, stateDD, setStateDD, scheduleReceivedItems, rescheduler, setRescheduler, selectedReceivedObject, setIsHiddenLoader, setSomethingWentWrongOpenPop, setSomethingMessage, selectReceivedItem, getLtoData, rescheduleId, setSelectReceivedItem } = useContext(PoDetailsContext);
  const classes = agreementGlobalStyles();
  const classes1: any = schedulePopupStyles();
  const [addressChanged, setAddressChanged] = useState(false);
  const [hideEdit, setHideEdit] = useState(true);
  const [Time, SetTime] = useState("");
  const [BindtimeslotId, setBindtimeslotId] = useState("");
  const [timeSlotData, SettimeSlotData]: any = useState([]);
  const [timeslotid, Settimeslotid] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [SaveTrigger, SetSaveTrigger] = useState(false);
  const [MaxDate, setMaxDate] = useState("");
  const [TimeSlotYes, setTimeSlotYes] = useState(false);
  const [ContextStartDate, SetContextStartDate] = useState("");
  const [ContextEndDate, SetContextEndDate] = useState("");
  let addressObj = {
    addressLine1: "",
    addressLine2: "",
    zip: "",
    state: "",
    city: "",
  }
  const [addressObject, setAddressObject] = useState<any>(addressObj);
  const [oldAddressObject, setOldAddressObject] = useState<any>(addressObj);
  const [oldTime, setOldTime] = useState<any>("");
  const [oldInstruction, setOldInstruction] = useState<any>("");
  const [oldCoWorkerReq, setOldCoWorkerReq] = useState<any>("");
  const [coWorkerReq, setCoWorkerReq] = useState("");
  const [instruction, setInstruction] = useState("");
  let statePayload = {
    label: "",
    value: ""
  }
  const [cowrokersDD, setCowrokersDD] = useState<any>([
    {
      label: "Select",
      value: "0",
    },
    { label: "1", value: "1", }, { label: "2", value: "2", }, { label: "3", value: "3", }, { label: "4", value: "4", }, { label: "5", value: "5", }, { label: "6", value: "6", },
  ]);
  const [itemInformationObject, setItemInformationObject] = useState<any>([{}]);
  const [stateValue, setstateValue] = useState();
  const [oldStateValue, setOldStateValue] = useState();
  useEffect(() => {
    // getStatesFn();
    console.log(rescheduler, "rescheuler in popup", scheduleReceivedItems);
    if (somethingWentWrongOpen) {
      console.log("Call bindTimeSlota", scheduleReceivedItems);
      if (rescheduler) {
        BindtimeslotDate = moment(scheduleReceivedItems[rescheduler - 1].deliveryDate).format('YYYY-MM-DD');
        console.log((scheduleReceivedItems[rescheduler - 1]), "reschedule clicked");
        setBindtimeslotId(manipulateTimeSlotId(scheduleReceivedItems[rescheduler - 1].deliveryTime));
        GetTimeSlot(moment(scheduleReceivedItems[rescheduler - 1].deliveryDate).format('YYYY-MM-DD'));
        // setAddressObject(scheduleReceivedItems[rescheduler - 1].deliveryAddress);
        // setOldAddressObject(scheduleReceivedItems[rescheduler - 1].deliveryAddress);
        addressMapper(scheduleReceivedItems[rescheduler - 1].deliveryAddress);
        setInstruction(scheduleReceivedItems[rescheduler - 1].instruction);
        setOldInstruction(scheduleReceivedItems[rescheduler - 1].instruction);
        setCoWorkerReq(JSON.stringify(scheduleReceivedItems[rescheduler - 1].coworkersReq));
        setOldCoWorkerReq(JSON.stringify(scheduleReceivedItems[rescheduler - 1].coworkersReq));
        SetTime(manipulateTime(scheduleReceivedItems[rescheduler - 1].deliveryTime));
        setOldTime(manipulateTime(scheduleReceivedItems[rescheduler - 1].deliveryTime));
        setStartDate(moment(scheduleReceivedItems[rescheduler - 1].deliveryDate).format("YYYY-MM-DD"));
      }
      else {
        SetTime("");
        setCoWorkerReq("")
        setStartDate("");
        setBindtimeslotId("");
        console.log("else block schedule click");
        GetTimeSlot(new Date());
        splitAddress(data.address)
      }
    }
  }, [somethingWentWrongOpen, data]);

  // async function getStatesFn() {
  //   let response = await GetTransactionType('entity/state_province');
  //   console.log(response.data.referenceDetails, "the response of the state ");
  //   let statesTemp = [...stateDD];
  //   response.data.referenceDetails?.map((val) => {
  //     statePayload = {
  //       value: val.abbreviation,
  //       label: val.stateProvinceName
  //     }
  //     if (rescheduler) {
  //       const deliveryAddress = scheduleReceivedItems[rescheduler - 1].deliveryAddress
  //       if (val.stateProvinceName == deliveryAddress.state) {
  //         console.log(val.abbreviation, "inside the state function", deliveryAddress);
  //         deliveryAddress["state"] = val.abbreviation;
  //         setOldAddressObject(deliveryAddress);
  //         setAddressObject(deliveryAddress);
  //       }
  //     }
  //     statesTemp.push(statePayload)
  //   })
  //   setStateDD(statesTemp);
  //   console.log("the state response ", statesTemp);
  //   //  setStateDD( "")
  // }
  function manipulateTimeSlotId(utcTimestamp: any) {
    console.log("the time payload into the function00", utcTimestamp);
    let timeModified = moment(utcTimestamp).utc().format("HH");
    let time = parseInt(timeModified);
    console.log("inside the manipulate time Id function", time);
    if (time <= 12)
      return (`${time}A`);
    else
      return (`${time - 12}P`)
  }
  function manipulateTime(utcTimestamp: any) {
    let timeModified = moment(utcTimestamp).utc().format("HH");
    let time = parseInt(timeModified);
    console.log("inside the manipulate time function", time);
    let timeValidater
    if (time > 12 && time < 24) {
      if (time > 22) {
        switch (time) {
          case 23:
            timeValidater = `${time}:00 PM - ${"1"}:00 AM`;
            break;
          case 24:
            timeValidater = `00:00 AM - ${2}:00 AM`
        }
      }
      else {
        let calc = time - 12;
        timeValidater = `${time - 12}:00 - ${time - 10}:00 PM`;
      }
    }
    else {
      if (time > 10) {
        switch (time) {
          case 11:
            timeValidater = `${time}:00 AM - ${"1"}:00 PM`
            break;
          case 12:
            timeValidater = `${time}:00 AM - ${"1"}:00 PM`
            break;
        }
      } else {
        timeValidater = `${time}:00 - ${time + 2}:00 AM`
      }
    }
    console.log(timeValidater, "manipulate time in card", utcTimestamp);
    return timeValidater;
  }
  const splitAddress = (address: string) => {
    if (!address)
      return " ";
    let count = 0;
    for (let i = 0; i < address.length; i++) {
      if (address[i] === ',') {
        count++;
      }
    }
    const parts = address.split(',');
    if (count == 4) {
      addressObj = {
        addressLine1: parts[0].trim(),
        addressLine2: parts[1].trim(),
        city: parts[2].trim(),
        state: parts[3].trim(),
        zip: parts[4].trim()
      }
    }
    else {
      addressObj = {
        addressLine1: parts[0].trim(),
        addressLine2: "",
        city: parts[1].trim(),
        state: parts[2].trim(),
        zip: parts[3].trim()
      }
    }
    console.log(addressObj, "addressObj resp");
    addressMapper(addressObj);
    // setAddressObject(addressObj);
    // setOldAddressObject(addressObj);
  }
  function addressMapper(address) {
    // debugger;
    console.log("Inside the add mapper", address);

    stateDD.map((val) => {
      if (val.label == address.state || val.value == address.state) {
        address['state'] = val.value
        console.log("Tehe address in the state", address);
        setOldStateValue(val.label);
        setstateValue(val.label);

      }
    })
    setAddressObject(address);
    setOldAddressObject(address);

  }
  const getslotdetails = (
    avail: any,
    modifiedStarttime: any,
    modifiedEndtime: any
  ) => {
    console.log(avail, modifiedStarttime, modifiedEndtime, "the arguments of the getSlotDetails function");
    // if (props?.triggertimeChanged !== undefined) {
    //     props.triggertimeChanged();
    // }
    console.log("ContextStart", modifiedStarttime);
    console.log("ContextEnd", modifiedEndtime);
    SetContextStartDate(modifiedStarttime);
    SetContextEndDate(modifiedEndtime);
    SetTime(avail.target.value);
    Settimeslotid(avail.target.id);
    setBindtimeslotId(avail.target.id);
    // BindtimeslotId = avail.target.id;
    BindtimeslotDate = avail.target.name;
    console.log(moment(BindtimeslotDate).format("YYYY-MM-DD"));
    setStartDate(moment(BindtimeslotDate).format("YYYY-MM-DD"));
    buildTimeSlot();
    SetSaveTrigger(false);
  };
  const changeDateFormat = (date: Date) => {
    // eslint-disable-next-line prefer-const
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      // eslint-disable-next-line prefer-const
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };
  const GetTimeSlot = async (StartingDate: any, ReSchdeuleSource?: any) => {
    SettimeSlotData([]);
    setStartDate(moment(StartingDate).format('YYYY-MM-DD'))
    //const startingdate = new Date(StartingDate);
    const startingdate = StartingDate;
    const maximumdate = moment(StartingDate)
      .add(3, "months")
      .format("YYYY-MM-DD");
    setMaxDate(maximumdate);
    const endingdate = moment(StartingDate).add(9, "days").format("YYYY-MM-DD");
    //const endingdate = addDays(startingdate, 9);
    console.log(endingdate);
    console.log("startingdateuh", startingdate);
    console.log("changeDateFormatok", changeDateFormat(startingdate));
    const formattedstartingdate = moment(startingdate).format("YYYY-MM-DD");
    const formattedenddate = moment(endingdate).format("YYYY-MM-DD");
    // const formattedstartingdate = changeDateFormat(startingdate);
    // const formattedenddate = changeDateFormat(endingdate);
    console.log("DAtsssssssrefd", formattedenddate);
    const payload = {
      isLoaner: "Y",
      customerId: String(1241435),
      endDate: formattedenddate,
      eventSource: ReSchdeuleSource !== undefined ? ReSchdeuleSource : "STO",
      startDate: formattedstartingdate,
      storeId: window.sessionStorage.getItem("storeNumber"),
    };
    const timeslotresponse = await GetTimeSlots(payload);
    console.log("time slot response", timeslotresponse);
    setTimeSlotYes(true);
    if (timeslotresponse?.data !== undefined) {
      // SetTimeslotReason(timeslotresponse);
      const TimeSlotsDetailsArrayValue: any = [];
      interface TimeSlotDetails {
        timeSlot: string;
        startTime: string;
        endTime: string;
        status: string;
      }
      interface TimeSlotDetailsArray {
        Date: string;
        TimeSlots: TimeSlotDetails;
      }
      const TimeSlotVariable = timeslotresponse?.data?.timeSlots;
      const sorted: any = Object.keys(TimeSlotVariable)
        .sort(function (a: any, b: any) {
          const date1: any = new Date(a);
          const date2: any = new Date(b);
          return date1 - date2;
        })
        .reduce((accumulator: any, key) => {
          accumulator[key] = TimeSlotVariable[key];
          return accumulator;
        }, {});
      Object.keys(sorted).forEach(function (key) {
        const value = sorted[key];
        //  console.log(key + ':' + value);
        const Timeslots: any = [];
        value.map((value: any) => {
          const timeslot_object = {} as TimeSlotDetails;
          timeslot_object.timeSlot = value.timeSlot;
          timeslot_object.startTime = value.startTime;
          timeslot_object.endTime = value.endTime;
          timeslot_object.status = value.status;
          Timeslots.push(timeslot_object);
        });
        const TotalTimeSlots = {} as TimeSlotDetailsArray;
        TotalTimeSlots.Date = key;
        TotalTimeSlots.TimeSlots = Timeslots;
        TimeSlotsDetailsArrayValue.push(TotalTimeSlots);
      });
      SettimeSlotData(TimeSlotsDetailsArrayValue);
      // SetTime('');
      // if (rescheduler) {
      //   setAddressObject(scheduleReceivedItems[rescheduler].deliveryAddress);
      //   setInstruction(scheduleReceivedItems[rescheduler].instruction);
      //   setOldInstruction(scheduleReceivedItems[rescheduler].instruction);
      //   setCoWorkerReq(JSON.stringify(scheduleReceivedItems[rescheduler].coworkersReq));
      //   setOldCoWorkerReq(JSON.stringify(scheduleReceivedItems[rescheduler].coworkersReq));
      //   SetTime(manipulateTime(scheduleReceivedItems[rescheduler].deliveryTime));
      //   setOldTime(manipulateTime(scheduleReceivedItems[rescheduler].deliveryTime));
      //   setStartDate(moment(scheduleReceivedItems[rescheduler].deliveryDate).format("YYYY-MM-DD"));
      // }
      // console.log('beoefevcdvbh', BindtimeslotId);
      // setBindtimeslotId('');
      // eslint-disable-next-line no-console
      console.log(TimeSlotsDetailsArrayValue, "timeslot converted array");
    }

    console.log(timeslotresponse?.data, "testingresponsetimeslot");
  };
  const timeslotbind = (objdate: any) => {
    if (objdate.TimeSlots != undefined) {
      console.log(objdate, "object time in bind time slot", BindtimeslotDate);
      return objdate.TimeSlots.map((objtime: any) => {
        console.log(objtime, BindtimeslotId, BindtimeslotDate, "object time in bind time slot", BindtimeslotId == objtime.timeSlot);
        const modifiedStarttime = moment(objtime.startTime, "HH:mm:ss").format(
          "LT"
        );
        const modifiedEndtime = moment(objtime.endTime, "HH:mm:ss").format(
          "LT"
        );
        const slicedTimeslot =
          modifiedStarttime.slice(0, 5) +
          modifiedStarttime.slice(8, modifiedStarttime.length);
        const Currently = new Date();
        const todayDate = String(Currently.getDate()).padStart(2, "0");
        const todayMonth = String(Currently.getMonth() + 1).padStart(2, "0"); //January is 0!
        const todayYear = Currently.getFullYear();
        const todaysDate = todayMonth + "/" + todayDate + "/" + todayYear;
        const TodayUpdatedDate = new Date(todaysDate);
        const ObjUpdatedDate = new Date(objdate.Date);
        if (
          TodayUpdatedDate == ObjUpdatedDate ||
          TodayUpdatedDate > ObjUpdatedDate
        ) {
          const startTime: any = objtime.startTime;
          const hoursdisplayed = startTime.split(":")[0];
          const minutesdisplayed = startTime.split(":")[1];
          const displayedTime = hoursdisplayed + ":" + minutesdisplayed;
          console.log(displayedTime);
          const Currently = new Date();
          const TimeatPresent = new Date(Currently.getTime());
          const currentHours = TimeatPresent.getHours();
          console.log(currentHours);
          const currentMinutes = TimeatPresent.getMinutes();
          console.log(currentMinutes);
          const Currenttime = currentHours + ":" + currentMinutes;
          console.log(Currenttime);
          if (objtime.status !== "Available") {
            let slotColor = "";
            if (
              BindtimeslotId !== "" &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== "" &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes1.slotBooked;
            } else {
              slotColor = classes1.slotBusy;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + "-" + modifiedEndtime}
                id={objtime.timeSlot}
                className={slotColor}
                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime)
                }
                disabled={true}
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          }
          else if (
            todaysDate > objdate.Date ||
            (todaysDate == objdate.Date && displayedTime <= Currenttime)
            // eslint-disable-next-line sonarjs/no-duplicated-branches
          ) {
            let slotColor = "";
            if (
              BindtimeslotId !== "" &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== "" &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes1.slotBooked;
            } else {
              slotColor = classes1.slotBusy;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + "-" + modifiedEndtime}
                id={objtime.timeSlot}
                className={slotColor}
                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime)
                }
                disabled={true}
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          } else {
            let slotColor = "";
            if (
              BindtimeslotId !== "" &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== "" &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes1.slotBooked;
            } else {
              slotColor = classes1.slotAvailable;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + "-" + modifiedEndtime}
                id={objtime.timeSlot}
                className={slotColor}
                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime)
                }
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          }
        } else {
          if (objtime.status !== "Available") {
            return (
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + "-" + modifiedEndtime}
                id={objtime.timeSlot}
                className={classes1.slotBusy}
                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime)
                }
                disabled={true}
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          } else {
            let slotColor = "";
            if (
              BindtimeslotId !== "" &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== "" &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes1.slotBooked;
            } else {
              slotColor = classes1.slotAvailable;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + "-" + modifiedEndtime}
                id={objtime.timeSlot}
                className={slotColor}
                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime)
                }
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          }
        }
      });
    }
  };
  const formatDate = (dates: any) => {
    const dateFormat = new Date(dates);
    console.log(dateFormat, "<--- Date Format");
    console.log(dateFormat.toUTCString(), "UTC");
    return (
      dateFormat.toUTCString().slice(0, 4) +
      dateFormat.toUTCString().slice(7, 12) +
      dateFormat.toUTCString().slice(5, 7)
    );
  };
  const buildTimeSlot = () => {
    if (timeSlotData != undefined && timeSlotData.length > 0) {
      let slotNumbers = 0;
      return timeSlotData.map((objdate: any) => {
        if (
          objdate.TimeSlots != null &&
          objdate.TimeSlots != "" &&
          slotNumbers < 5
        ) {
          slotNumbers++;
          const formattedDate = formatDate(objdate.Date);
          console.log(objdate, "times slot bind");
          return (
            // eslint-disable-next-line react/jsx-key
            <Grid item className={`${classes1.agrTimeSlot}`}>
              <Typography
                className={`${classes1.semiBold} ${classes.font14} ${classes1.MarginDate}`}
              >
                {formattedDate}
              </Typography>
              <Grid className={classes1.agrListGroup}>
                {timeslotbind(objdate)}
              </Grid>
            </Grid>
          );
        }
      });
    } else {
      return (
        <div className={classes1.Norecords}>
          <CircularProgress />
        </div>
      );
    }
  };
  const onDateChangeSchedulePopUp = (e: any) => {
    console.log(e, "date picker");
    const format: any = moment(e).format("MM/DD/YYYY");
    const temp = e.replace(format);
    console.log(e, "Date Information");
    setStartDate(temp);
    SetTime("");
    GetTimeSlot(moment(temp).format('YYYY-MM-DD'));
  };
  const onScheduleInfoChange = (e: any) => {
    console.log(e.target.value, "input DROPDOWN data");
    setInstruction(e.target.value)
  };
  const onPopUpclose = () => {
    setRescheduler(0);
    setSomethingWentWrongOpen(false);
    SetContextStartDate("");
    SetContextEndDate("");
    Settimeslotid("");
    setBindtimeslotId("");
    setInstruction(oldInstruction);
    setCoWorkerReq(oldCoWorkerReq);
    setSomethingWentWrongOpen(false);
    setAddressObject(oldAddressObject);
    SetTime(oldTime);
    setInstruction(oldInstruction);
    setCoWorkerReq(oldCoWorkerReq);
    setOldStateValue(oldStateValue);
    setstateValue(stateValue);
    setSomethingWentWrongOpen(false);
    setAddressObject(oldAddressObject);
    SetTime(oldTime);
    setInstruction(oldInstruction);
    setCoWorkerReq(oldCoWorkerReq);
    setSomethingWentWrongOpen(false);
  }
  const SaveAddress = () => {
    stateDD.map((val) => {
      if (val.value == addressObject.state) {
        setOldStateValue(val.label);
        setstateValue(val.label);
      }
    }
    )
    setOldAddressObject(addressObject)
  }
  const timeSlotPopupFn = () => {
    return (
      <>
        <Grid style={{ padding: "0px 10px" }}>
          <Grid style={{ padding: "0px 0px 20px 0px", margin: '0px 0px 30px  0px !important' }}>
            <Typography  /* className={classes.deliveryDetails} */ variant="h4" style={{ paddingLeft: "08px" }}>
              Delivery Details
            </Typography>
          </Grid>
          <Grid container spacing={3} style={{ height: "150px" }}>
            <Grid item md={2} container>
              <Typography variant="h5" className={classes1.alignDeliveryType} style={{ paddingLeft: "08px" }}>
                Delivery Type
              </Typography>
              <Grid item md={6} style={{ marginTop: "2%" }}>
                <RACRadio
                  name="inlineRadioOptions"
                  id="delivery"
                  checked={true}
                  value={""}
                  size={"small"}
                  label={"Delivery"}
                  disabled={true}
                />
              </Grid>
              <Grid item md={6} style={{ marginTop: "2%" }}>
                <RACTooltip
                  placement={'top-start'}
                  className={classes1.tooltipcss}
                  title="Carry out is not eligible for Acima Po Receiving
"
                >
                  <div>
                    <RACRadio
                      name="inlineRadioOptions"
                      id="carryout"
                      checked={false}
                      value={""}
                      size={"small"}
                      label={"Carryout"}
                      disabled={true}
                    />
                  </div>
                  {/* <Info title="Carryout is not available" /> */}
                </RACTooltip>
              </Grid>
            </Grid>
            <Grid item md={10} container>
              <Typography variant="h5" /* className={classes.deliverytype} */ style={{ paddingLeft: "15px" }}>
                Delivery Address
              </Typography>
              <Grid md={12} container>
                <Grid item md={5} container>
                  <Grid item md={6} style={{ padding: "15px" }}>
                    <Typography
                      className={classes.formLabel}
                    >
                      Address Line 1
                      {console.log(addressObject, "The addresss object in 651")}
                    </Typography>

                    {hideEdit ? (

                      <Typography className={classes1.formLabelPopup} style={{ padding: "3px" }}>
                        {addressObject.addressLine1}
                      </Typography>
                    ) : (
                      <RACTextbox
                        isCurrency={false}
                        maxlength={30}
                        id="CustomAlert"
                        value={addressObject.addressLine1}
                        name={"addressLine1"}
                        OnChange={(e: any) => CustomAddressval(e)}
                      />
                    )}
                  </Grid>
                  <Grid item md={6} style={{ padding: "15px" }}>
                    <Typography
                      className={classes.formLabel}
                    >
                      Address Line 2
                    </Typography>
                    {hideEdit ? (
                      <Typography className={classes1.formLabelPopup}>
                        {addressObject.addressLine2}
                      </Typography>
                    ) : (
                      <RACTextbox
                        isCurrency={false}
                        maxlength={30}
                        id="CustomAlert"
                        value={addressObject.addressLine2}
                        name={"addressLine2"}
                        OnChange={(e: any) => CustomAddressval(e)}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item md={4} container>
                  <Grid item md={4} style={{ padding: "15px" }}>
                    <Typography
                      className={classes.formLabel}
                    >
                      Zip
                    </Typography>
                    {hideEdit ? (
                      <Typography className={classes1.formLabelPopup}>
                        {addressObject.zip}
                      </Typography>
                    ) : (
                      <RACTextbox
                        isCurrency={false}
                        id="CustomAlert"
                        value={addressObject.zip}
                        maxlength={10}
                        name={"zip"}
                        // type={"number"}
                        OnChange={(e: any) => {

                          CustomAddressval(e)
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} style={{ padding: "15px" }}>
                    <Typography
                      className={classes.formLabel}
                    >
                      City
                    </Typography>
                    {hideEdit ? (
                      <Typography className={classes1.formLabelPopup}>
                        {addressObject.city}
                      </Typography>
                    ) : (
                      <RACTextbox
                        isCurrency={false}
                        id="CustomAlert"
                        value={addressObject.city}
                        maxlength={30}
                        name={"city"}
                        OnChange={(e: any) => {
                          const regex = /^[A-Za-z]+$/;
                          if (regex.test(e.target.value) || e.target.value == "")
                            CustomAddressval(e)
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} style={{ padding: "17px 0px" }}>
                    {hideEdit ? (
                      <div>
                        <Typography
                          className={classes.formLabel}
                        >
                          State
                        </Typography>
                        <Typography className={classes1.formLabelPopup}>
                          {stateValue}
                        </Typography>
                      </div>
                    ) : (
                      <RACSelect
                        inputLabel="State"
                        isDisabled={false}
                        options={stateDD}
                        name={"state"}
                        loading={stateDD.length > 1 ? false : true}
                        defaultValue={
                          (addressObject.state)
                            ? addressObject.state
                            : "0"
                        }
                        onChange={(e: any) => {
                          console.log(e.target, "the is of the state", e.target.value);
                          setAddressObject({ ...addressObject, ['state']: e.target.value })
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item md={3} container>
                  {hideEdit ? (
                    <Grid item md={12} style={{ margin: "12%" }}>
                      <RACButton
                        color="primary"
                        variant="text"
                        onClick={() => {
                          setHideEdit(false);
                        }}
                      >
                        Edit Address
                      </RACButton>
                    </Grid>
                  ) : (
                    <Grid md={12} container style={{ padding: "1%" }}>
                      <Grid
                        item
                        md={6}
                        style={{ paddingLeft: "13%", marginTop: "14%" }}
                      >
                        <RACButton
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={() => {
                            setHideEdit(true);
                            setAddressObject(oldAddressObject);
                            setstateValue(oldStateValue); 
                          }}
                          style={{ padding: "4px" }}
                        >
                          cancel
                        </RACButton>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        style={{ paddingLeft: "5%", marginTop: "14%" }}
                      >
                        <RACButton
                          style={{ padding: "4px" }}
                          variant="contained"
                          size="small"
                            color="primary"
                            disabled={addressObject.addressLine1 && (addressObject.zip.length == 5 || addressObject.zip.length == 10 ) && (addressObject.state!="0") && addressObject.city ? false : true}
                          onClick={() => {
                            setHideEdit(true);
                            SaveAddress()
                            setAddressChanged(true);
                          }}
                        // style={{ padding: "1px" }}
                        // className={acimaDeliveryClasses.footerRight}
                        >
                          save
                        </RACButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2}>
              <RACDatePicker
                label="Date"
                disabled={false}
                value={startDate}
                onChange={(e: any) => {
                  onDateChangeSchedulePopUp(e);
                }}
                inputProps={{
                  min: moment(new Date()).add(0, "d").format("YYYY-MM-DD"),
                }}
              />
            </Grid>
            <Grid item md={2}>
              <RACTextbox
                isCurrency={false}
                id="a11y_time"
                disabled
                value={Time}
                // value={Time}
                inputlabel="Time"
              // OnChange={(e: any) => {}}
              />
            </Grid>
            <Grid item md={2}>
              <RACSelect
                inputLabel="# Co-workers Required"
                loading={false}
                isDisabled={false}
                options={cowrokersDD}
                name={"coworkersReq"}
                defaultValue={
                  (coWorkerReq)
                    ? coWorkerReq
                    : "0"
                }
                onChange={(e: any) => {
                  setCoWorkerReq(e.target.value)
                }}
              />
            </Grid>
            <Grid item md={6} >
              <RACTextbox
                inputlabel="Instructions"
                id="a11y_Instructions"
                isCurrency={false}
                name="instruction"
                value={instruction}
                // value=""
                className={classes1.InstructionTextBox}
                disabled={false}
                OnChange={(e: any) => {
                  onScheduleInfoChange(e);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12} md={12} lg={8} className={classes1.spacerMT8}>
              <Grid className={classes1.floatLeft}>
                <Typography variant="h5" className={classes1.subTitle}>
                  Select Available Timeslot
                </Typography>
                <Typography className={classes1.deliveryEventDesc}>
                  Only 4 events can be added in one time slot.
                </Typography>
              </Grid>
              <Grid className={classes.floatRight}>
                <Grid className={`${classes.floatLeft} ${classes1.me48}`}>
                  <span className={classes1.circleAvailable} />
                  <span>Available</span>
                </Grid>
                <Grid className={`${classes.floatLeft} ${classes1.spacerMR4}`}>
                  <span className={classes1.circleBusy} />
                  <span>Busy</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} className={classes1.spacerMB5}>
              <Grid className={`${classes.mt2} ${classes.me2}`}>
                {buildTimeSlot()}
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ float: "right" }}>
            <RACButton
              color="default"
              variant="outlined"
              style={{ marginRight: "25px" }}
              onClick={() => {

                onPopUpclose();
                // setScheduleInfo(scheduleObj);
              }}
            >
              Cancel
            </RACButton>
            <RACButton
              color="primary"
              variant="contained"
              disabled={Time && hideEdit ? false : true}
              onClick={() => {
                scheduleAppointment()
              }
              }
            >
              {rescheduler ? "Reschedule" : "Schedule"}
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const CustomAddressval = (e: any) => {
    if (e.target.name == "zip") {
      let input = e.target
      // Remove any non-numeric characters
      let zip = input.value.replace(/\D/g, '');
      // Add a hyphen after the 5th character if necessary
      if (zip.length > 5) {
        zip = zip.slice(0, 5) + '-' + zip.slice(5);
      }
      // Limit the ZIP code to 10 characters
      if (zip.length > 10) {
        zip = zip.slice(0, 10);
      }
      // Update the input value
      e.target.value = zip;
    }
    console.log(e.target.value, "input data");
    // if (e.target.name == "zip" && fieldtype.test((e.target.value)))
    // return;
    setAddressObject({ ...addressObject, [e.target.name]: e.target.value })
  };
  const scheduleAppointment = async () => {
    console.log("inside the reschedule appointment function", sessionStorage.getItem('storeNumber'));
    console.log("The appointment is", scheduleReceivedItems[rescheduler - 1]);
    console.log("The store number", sessionStorage.getItem('storeNumber'));
    console.log(rescheduler, "reschedluer ");
    setIsHiddenLoader(true);
    if (rescheduler) {
      //update appointment
      let payload = {
        "storeNumber": `${sessionStorage.getItem('storeNumber')}`,
        "address_id": "02357",
        "eventInformation": {
          "partyId": "33925768",
          "agreementId": "127839016",
          "inventoryIds": [
            "9999207382277"
          ],
          "type": "Delivery",
          "eventDate": "11/23/2021",
          "eventSource": "Store",
          "eventId": "Sample",
          "timeSlot": {
            "timeSlotId": "9999100000392"
          },
          "eventInstructions": "created customer event",
          "requiredCoworkers": coWorkerReq,
          "racdbSourced": true,
          "isAcimaPo": "Y",
          "acimaCreate": {
            "timeSlot": `${BindtimeslotId}`,
            "externalNumber": "564956525",
            "appointmentInfo": {
              "appointmentType": "AD",
              "appointmentDate": startDate,
              "requiredCoworkers": coWorkerReq,
              "instructions": instruction,
            },
            "appointmentId": `${scheduleReceivedItems[rescheduler - 1].appointmentId}`,
            "storeNumber": `${sessionStorage.getItem('storeNumber')}`
          }
        }
      }
      console.log("reschule appointment", payload);
      const response = await acimascheduleInformation(payload);
      debugger
      console.log(response, "response acmima")
      if (response.status == 500) {
        console.log("Inside the 500 status appointment");
        setSomethingWentWrongOpen(false);
        setIsHiddenLoader(false);
        setSomethingMessage("Unable to schedule delivery");
        setSomethingWentWrongOpenPop(true);
        return;
      }
      if (response.status == 400) {
        setSomethingWentWrongOpen(false);
        setIsHiddenLoader(false);
        setSomethingMessage("Unable to schedule delivery");
        setSomethingWentWrongOpenPop(true);
        return;
      }
      console.log(oldAddressObject != addressObject, "Comparing address");
      if (addressChanged) {
        console.log("inside the address chnage")
        // console.log(selectReceivedItem.map((eachNumber: any) => Number(eachNumber)), "selectReceivedItem.filter((eachNumber) => Number(eachNumber))")
        let managePoPayload = {
          "purchaseOrderNumber": String(data?.purchaseOrderNumber),
          "poDetailReceivedId": rescheduleId,
          "appointmentId": response.data?.appointmentInfo?.appointmentId,
          "address": {
            addressLine1: addressObject.addressLine1,
            addressLine2: addressObject.addressLine2,
            state: addressObject.state,
            city: addressObject.city,
            zip: addressObject.zip,
          }
        }
        setSomethingWentWrongOpen(false);
        // setIsHiddenLoader(false);
        console.log("The manaage payload", managePoPayload, "", response, "The response of the manage");
        if (data.purchaseOrderNumber && response.data.appointmentInfo.appointmentId) {
          console.log(data.purchaseOrderNumber, response.data?.appointmentInfo?.appointmentId, "Appointment checkkkkkk")
          const resp = await managePoAcimaFn(managePoPayload);
          console.log(resp);
          if (resp.status == 500 || resp.status == 400) {
            setSomethingWentWrongOpen(false);
            setIsHiddenLoader(false);
            setSomethingMessage("Unable to schedule delivery");
            setSomethingWentWrongOpenPop(true);
            setRescheduler(0);
            setSelectReceivedItem([]);
            return;
          }
          SetTime("");
          setRescheduler(0);
          setSelectReceivedItem([]);
          setStartDate("");
          setCoWorkerReq("")
          setInstruction("");
        }
        else {
          setSomethingWentWrongOpen(false);
          setRescheduler(0);
          setSelectReceivedItem([]);
          setIsHiddenLoader(false);
          setSomethingMessage("Unable to schedule delivery");
          setSomethingWentWrongOpenPop(true);
          return;
        }
      }
      console.log("end of if in reschedule")
      await getLtoData();
      setRescheduler(0);
      setSelectReceivedItem([]);
      setSomethingWentWrongOpen(false);
      //   setIsHiddenLoader(false);
    }
    else {
      //   setIsHiddenLoader(true);
      // create appointment   
      let customerNameStr = data.customerName;
      let customerName = customerNameStr.split(" ");
      console.log("Schedule function submit", customerName);
      let payload = {
        "storeNumber": `${sessionStorage.getItem('storeNumber')}`,
        "address_id": "02357",
        "eventInformation": {
          "partyId": "33925768",
          "agreementId": "127839016",
          "inventoryIds": [
            "9999207382277"
          ],
          "type": "Delivery",
          "eventDate": "11/23/2021",
          "eventSource": "Store",
          "eventId": "Sample",
          "timeSlot": {
            "timeSlotId": "9999100000392"
          },
          "eventInstructions": "created customer event",
          "requiredCoworkers": coWorkerReq,
          "racdbSourced": true,
          "isAcimaPo": "Y",
          "acimaCreate": {
            "storeNumber": `${sessionStorage.getItem("storeNumber")}`,
            "customerId": null,
            "timeSlot": `${BindtimeslotId}`,
            "externalNumber": "564956525",
            "appointmentInfo": {
              "appointmentType": "AD",
              "instructions": instruction,
              "appointmentSource": "STO",
              "requiredCoworkers": (coWorkerReq == '0' || coWorkerReq == '') ? null : coWorkerReq,
              "title": "string",
              "appointmentDate": `${startDate}`
            },
            "additionalDetails": {
              poNumber: String(data?.purchaseOrderNumber),
              customerInfo: {
                addressLine1: addressObject.addressLine1,
                addressLine2: addressObject.addressLine2,
                state: addressObject.state,
                city: addressObject.city,
                zip: addressObject.zip,
                phoneNumber: data.phoneNumber,
                emailId : data.email,
                firstName: customerName[0]?.trim(),
                lastName: customerName[1]?.trim(),
              }, itemInformation: selectedReceivedObject,
            }
          }
        }
      }
      console.log(payload, "acimascheduleInformation payload");
      const response = await acimascheduleInformation(payload);
      console.log(response, "response acmima acimascheduleInformation")
      if (response.status == 500) {
        setSomethingWentWrongOpen(false);
        setIsHiddenLoader(false);
        setRescheduler(0);
        setSelectReceivedItem([]);
        setSomethingMessage("Unable to schedule delivery");
        setSomethingWentWrongOpenPop(true);
        return;
      }
      if (response.status == 400) {
        setSomethingWentWrongOpen(false);
        setIsHiddenLoader(false);
        setSomethingMessage("Unable to schedule delivery");
        setSomethingWentWrongOpenPop(true);
        return;
      }
      let managePoPayload = {};
      if (!addressChanged) {
        console.log(selectReceivedItem.map((eachNumber: any) => Number(eachNumber)), "selectReceivedItem.filter((eachNumber) => Number(eachNumber))")
        managePoPayload = {
          "purchaseOrderNumber": String(data?.purchaseOrderNumber),
          "poDetailReceivedId": selectReceivedItem.map((eachNumber: any) => String(eachNumber)),
          "appointmentId": String(response.data?.appointmentInfo?.appointmentId),
        }
        setSomethingWentWrongOpen(false);
        // setIsHiddenLoader(false);
        console.log(data.purchaseOrderNumber, response.data.appointmentInfo.appointmentId, "Appointment checkkkkkk")
        if (data.purchaseOrderNumber && response.data.appointmentInfo.appointmentId) {
          const resp = await managePoAcimaFn(managePoPayload);
          console.log(resp);
          if (resp.status == 500 || resp.status == 400) {
            setSomethingWentWrongOpen(false);
            setIsHiddenLoader(false);
            setSomethingMessage("Unable to schedule delivery");
            setSomethingWentWrongOpenPop(true);
            setRescheduler(0);
            setSelectReceivedItem([]);
            return;
          }
          SetTime("");
          setRescheduler(0);
          setSelectReceivedItem([]);
          setStartDate("");
          setCoWorkerReq("")
          setInstruction("");
        }
        else {
          setSomethingWentWrongOpen(false);
          setIsHiddenLoader(false);
          setSomethingMessage("Unable to schedule delivery");
          setSomethingWentWrongOpenPop(true);
          return;
        }
        setSomethingWentWrongOpen(false);
        // const resp = await managePoAcimaFn(managePoPayload);
        // console.log(resp);
      }
      else {
        console.log(selectReceivedItem.map((eachNumber: any) => Number(eachNumber)), "selectReceivedItem.filter((eachNumber) => Number(eachNumber))")
        managePoPayload = {
          "purchaseOrderNumber": String(data?.purchaseOrderNumber),
          "poDetailReceivedId": selectReceivedItem.map((eachNumber: any) => String(eachNumber)),
          "appointmentId": String(response.data?.appointmentInfo?.appointmentId),
          "address": {
            addressLine1: addressObject.addressLine1,
            addressLine2: addressObject.addressLine2,
            state: addressObject.state,
            city: addressObject.city,
            zip: addressObject.zip,
          }
        }
        console.log(data.purchaseOrderNumber, response.data.appointmentInfo.appointmentId, "Appointment checkkkkkk")
        if (data.purchaseOrderNumber && response.data.appointmentInfo.appointmentId) {
          const resp = await managePoAcimaFn(managePoPayload);
          console.log(resp);
          if (resp.status == 500 || resp.status == 400) {
            setSomethingWentWrongOpen(false);
            setIsHiddenLoader(false);
            setSomethingMessage("Unable to schedule delivery");
            setSomethingWentWrongOpenPop(true);
            setRescheduler(0);
            setSelectReceivedItem([]);
            return;
          }
          SetTime("");
          setStartDate("");
          setCoWorkerReq("")
          setInstruction("");
        }
        else {
          setSomethingWentWrongOpen(false);
          setIsHiddenLoader(false);
          setSomethingMessage("Unable to schedule delivery");
          setSomethingWentWrongOpenPop(true);
          setRescheduler(0);
          setSelectReceivedItem([]);
          return;
        }
      }
      console.log(response);
      await getLtoData();
      setSomethingWentWrongOpen(false);
      console.log("end of else in reschedule")
      setRescheduler(0);
      setSelectReceivedItem([]);
    }
    setIsHiddenLoader(false);
  }
  return (
    <>
      <RACModalCard
        isOpen={somethingWentWrongOpen}
        closeIcon={true}
        maxWidth="lg"
        borderRadius={"10px"}
        className={classes1.PopUpComponentsStyles}
        //   children={buildTimeSlot}
        onClose={() => {
          console.log("button clicked");
          onPopUpclose();

        }}
      >
        {timeSlotPopupFn()}
        {/* {somethingWentWrongPopup()} */}
      </RACModalCard>
    </>
  )
}