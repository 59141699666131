/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
/* eslint-disable */
// // DataContext.js
import React, { createContext, useEffect, useState } from 'react';
import { GetStatsStatus, GetTransactionType, getLtoPurchaseOrderDetail } from '../../../api/user'
import { useParams } from 'react-router-dom';

export const PoDetailsContext = createContext<any>('');

export function PoDetailsProvider(props: any) {


  const [data, setData]: any = useState("");
  /**Pending and received item list state varible */
  const [nonReceivedItems, setNonReceivedItems] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectReceivedItem, setSelectReceivedItem] = useState([]);
  const [scheduleReceivedItems, setScheduleReceivedItems] = useState<any>([]);
  const [somethingWentWrongOpen, setSomethingWentWrongOpen] = useState(false);
  const [somethingWentWrongOpenPop, setSomethingWentWrongOpenPop] = useState(false);
  const [errorMessagePopupOpenPop, setErrorMessagePopupOpenPop] = useState(false);
  const [manageAgrErrMessage, setManageAgrErrMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isHiddenLoader, setIsHiddenLoader] = useState(false);
  const [somethingMessage, setSomethingMessage] = useState("");
  const [rescheduler, setRescheduler] = useState(0);
  const [selectedReceivedObject, setSelectedreceivedObject] = useState([]);
  const [rescheduleId, setRscheduleId] = useState([])
  const [isDeliveryReceiptOpen, setIsDeliveryReceiptOpen] = useState(false);
  const [signInData, setSignInData]: any = useState([]);

  const [isManualOpen, setIsManualOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isConfirmedOpen, setIsConfirmedOpen] = useState(false);

  const [isPageLoaded, setIsPageLoaded] = useState(false);
  let statePayload = {
    label: "",
    value: ""
  }
  const [stateDD, setStateDD] = useState([{
    label: "Select",
    value: "0",
  }]);

  const [isReversePoOpen, setIsReversePoOpen] = useState(false);
  const [reverseArray, setReverseArray] = useState([]);
  const [selectedReverseItem, setSelectedReverseItem] = useState([]);

  const [isSignedByCustomer,setIsSignedByCustomer] = useState(false);
  const [acimaPoReceive,setacimaPoReceive] = useState('0')

  const [isPrintPoOpen, setIsPrintPoOpen] = useState(false);

  interface Params {
    ponumber: string;
  }
  const { ponumber } = useParams<Params>();
  console.log(ponumber, "this is the po number for the api cal")
  async function getStatesFn() {
    let response = await GetTransactionType('entity/state_province');
    let statesTemp = [...stateDD];
    response.data.referenceDetails?.map((val) => {
      statePayload = {
        value: val.abbreviation,
        label: val.stateProvinceName
      }
      statesTemp.push(statePayload)
    })
    setStateDD(statesTemp);
  }

  const getLtoData = async (type: any) => {
    try {

      if (type == "Load") {
        setIsLoading(true);
      }

      if(type == "schedule"){
        setIsHiddenLoader(true)
      }

      console.log("I am called from the useEffect for the data!!!");
      const ltoData: any = await getLtoPurchaseOrderDetail({ poNumber: ponumber });

      console.log(ltoData, "This is the lto data!!!");
      if (ltoData?.data?.errors) {
        setManageAgrErrMessage(ltoData?.data?.errors[0]?.error);
        setErrorMessagePopupOpenPop(true);
        return;
      }

      if (ltoData?.status == 500) {
        setSomethingWentWrongOpenPop(true);
        setSomethingMessage("Something went wrong")
      }



      const dataForBind: any = ltoData.data;
      for (const [index, values] of dataForBind.itemInformation.entries()) {
        if (values.poDetailReceivedId == null) {
          values.poDetailReceivedId = index;
          values.isNew = true;
        }
        if (values.receivingNow == null) {
          values.receivingNow = 'All';
        }
        //  if(!(values.itemStatus == "Received")){
        //   values.bol = "";
        // }
      }

      setData(dataForBind);
      if (dataForBind.itemInformation) {
        console.log(dataForBind.itemInformation, "dataForBind.itemInformation")
        setNonReceivedItems(dataForBind.itemInformation);
      }

      setScheduleReceivedItems(dataForBind.scheduledInformation)

      //reverse Po Starts here;
      const items = dataForBind.itemInformation;
      const schedule = dataForBind.scheduledInformation;

      let eleigibleReverseItemArray: any = [];

      let i = 0;
      for (let values of items) {
        if (values.itemStatus == "Received") {
          values.reverseId = i;
          eleigibleReverseItemArray.push(values);
          i++;
        }
      }

      for (let values of schedule) {
        if (values.deliveryStatus == "ToDo" || values.deliveryStatus == "Completed") {
          for (let valuesItem of values.itemInformation) {
            valuesItem.reverseId = i;
            valuesItem.itemStatus = values.deliveryStatus
            eleigibleReverseItemArray.push(valuesItem);
            i++
          }
        }
      }

      //to sort the data in reverse => 
      eleigibleReverseItemArray.sort((a: any, b: any) => {
        const statusOrder = {
          "Received": 0,
          "ToDo": 1,
          "Completed": 2
        };
        return statusOrder[a.itemStatus] - statusOrder[b.itemStatus];
      });


      // console.log(JSON.stringify(eleigibleReverseItemArray),"There is a commit");

      setReverseArray(eleigibleReverseItemArray);

      //reverse po ends here

      if (type == "Load") {
        setIsPageLoaded(true);
        setIsLoading(false);
      }

      if(type == "schedule"){
        setIsHiddenLoader(false)
      }

      return ltoData.data;

    } catch (error: any) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getStatesFn();
    getLtoData("Load");
  }, []);

  //getfeatureflag;
//   useEffect(() => {
//     getConfigDetailsFn();
// }, [])
// const getConfigDetailsFn = async () => {
//     const payload: any = {
//         storeNumbers: [window.sessionStorage.getItem('storeNumber')],
//         paramKeyNames: ["PilotStore"],
//     };
//     const getConfigDetailsRes = await GetStatsStatus(payload);
//     const storeFeature = getConfigDetailsRes?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.filter((el: any) => el.paramKeyName == 'PilotStore')[0].paramValue;
//     console.log("featureFlag",storeFeature);
//     setacimaPoReceive(getConfigDetailsRes?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.filter((el: any) => el.paramKeyName == 'PilotStore')[0].paramValue)
// }

  return (
    <div>
      <PoDetailsContext.Provider
        value={{
          data, setData, nonReceivedItems, setNonReceivedItems, selectedItem, setSelectedItem, selectReceivedItem, setSelectReceivedItem, getLtoData, scheduleReceivedItems, setScheduleReceivedItems, somethingWentWrongOpen, setSomethingWentWrongOpen, somethingWentWrongOpenPop, setSomethingWentWrongOpenPop, errorMessagePopupOpenPop, setErrorMessagePopupOpenPop, manageAgrErrMessage, setManageAgrErrMessage, isLoading, setIsLoading, isHiddenLoader, setIsHiddenLoader, somethingMessage, setSomethingMessage,
          rescheduler,
          setRescheduler,
          selectedReceivedObject, setSelectedreceivedObject,
          isDeliveryReceiptOpen, setIsDeliveryReceiptOpen,
          signInData, setSignInData,
          isManualOpen, setIsManualOpen,
          isConfirmed, setIsConfirmed,
          isConfirmedOpen, setIsConfirmedOpen,
          isPageLoaded, setIsPageLoaded,
          reverseArray, setReverseArray,
          selectedReverseItem, setSelectedReverseItem,
          isReversePoOpen, setIsReversePoOpen,
          isSignedByCustomer, setIsSignedByCustomer,
          rescheduleId, setRscheduleId,
          acimaPoReceive,setacimaPoReceive,
          isPrintPoOpen, setIsPrintPoOpen,
          stateDD, setStateDD
        }} >
        {props.children}
      </PoDetailsContext.Provider>
    </div>
  );
}