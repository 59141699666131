/* eslint-disable */
import React, { useContext, useState } from "react"
import { PoDetailsContext } from "./context/PoDetailsContext";
import { managePoAcimaFn, updateStatusCall } from "../../api/user";
import { useParams } from 'react-router-dom';
import DeliveryDigitalSign from "./SigninAndDeliveryShared";
export const DeliveryConfirm = () => {
    const [uploadeDocLoader, setUploadeDocLoader] = useState(false);
    const [isSendDeliveryReceiptOpen, setIsSendDeliveryReceiptOpen] = useState(false);
    const { somethingWentWrongOpen, setSomethingWentWrongOpen, data, scheduleReceivedItems, rescheduler, selectedReceivedObject, setIsHiddenLoader, setSomethingWentWrongOpenPop, setSomethingMessage, selectReceivedItem, getLtoData, setIsDeliveryReceiptOpen, signInData, setSignInData,isManualOpen,setIsManualOpen,setIsConfirmedOpen } = useContext(PoDetailsContext);
    const customerDetails = {
        customerName: data?.customerName,
        addressLine1: signInData?.deliveryAddress?.addressLine1,
        addressLine2: signInData?.deliveryAddress?.addressLine2,
        zip: signInData?.deliveryAddress?.zip,
        city: signInData?.deliveryAddress?.city,
        state: signInData?.deliveryAddress?.state,
        itemDetails: signInData?.itemInformation,
        phoneNumber: data?.phoneNumber,
        appointmentId: Number(signInData.appointmentId),
        date :signInData.deliveryDate ,
        time :signInData.deliveryTime,
        emailId : data.email
    }
    interface Params {
        ponumber: string;
    }
    const { ponumber } = useParams<Params>();
    const confirmDelivery = async () => {
        if(isManualOpen){
            setIsHiddenLoader(true);
        }
  
        console.log("I am called from the continue delivery call")
        let payLoad = {
            "agreementId": "dibshfdkn", // static data
            "isAcimaPo": "Y",
            "acimaCreate": {
                "actionName": "Complete",
                "appointmentId": signInData.appointmentId,
                "storeNumber": window.sessionStorage.getItem('storeNumber')
            }
        }

        const payLoadForm = {
            "purchaseOrderNumber": String(data?.purchaseOrderNumber),
            "poDetailReceivedId": signInData.itemInformation.map((value:any,index:number) => {
                return value.poDetailReceivedId
            })            ,
            "appointmentId": signInData.appointmentId,
        }

        const resp = await updateStatusCall(payLoad);
        const updateManage = await managePoAcimaFn(payLoadForm);
        setUploadeDocLoader(false);
        setIsSendDeliveryReceiptOpen(true);

        await getLtoData();
        console.log("I am called this is the payload for the data to be delivered for the data", payLoad)
        if(isManualOpen){
            setIsHiddenLoader(false);
        }
        setIsManualOpen(false)
        // setIsConfirmedOpen(true);
    }
    return (
        data ? 
            <DeliveryDigitalSign agreementId={""} sundaySky={() => { }} deliveryDigitalSignCompletedFn={
                confirmDelivery} deliveryDigitalSignCanceledFn={() => { }} customerDetails={customerDetails} isManual={isManualOpen} poNumber={ponumber} setUploadeDocLoader={setUploadeDocLoader} setIsSendDeliveryReceiptOpen={setIsSendDeliveryReceiptOpen} isSendDeliveryReceiptOpen={isSendDeliveryReceiptOpen} uploadeDocLoader={uploadeDocLoader}  /> : <></>
    )
}