/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable */
import { Card, CardContent, Grid, List, ListItem, Typography } from "@rentacenter/racstrap"
import { useHistory } from "react-router-dom";
import React, { useContext } from "react";
import { PoDetailsContext } from "./context/PoDetailsContext";
import moment from "moment";
import { PoDetailsBreadCrum } from "./PoDetailsBreadCrum";
import { takePaymentStyles } from "./PoDetailStyles/makeStyles";
import { agreementGlobalStyles } from "./PoDetailStyles/agreementGlobalStyles";
import { agreementDeliveryStyles } from "./PoDetailStyles/agreementDeliveryStyles";

export const CustomerDetails = () => {
    /*CSS Imports starts here */
    const takepaymentclasses = takePaymentStyles();
    const classes = agreementGlobalStyles();
    const acimaDeliveryClasses = agreementDeliveryStyles();
    /*CSS Imports ends heer */
    /**useContext data stats here */
    const { data, setData, isLoading,isPageLoaded } = useContext(PoDetailsContext);
    /**useContext data ends here */

    const manipulateAddress = (object: string): string => {
        if (object) {
            let obj = object.split(",");
            let address: string = "";
            if (obj.length == 5) {
                address =
                    obj[0] +
                    ", " +
                    obj[1] + ", "
                    + obj[2] +
                    ", " +
                    obj[3] +
                    " " +
                    obj[4];
            }
            else {
                address =
                    obj[0] +
                    ", "
                    + obj[1] +
                    ", " +
                    obj[2] +
                    ", " +
                    obj[3];
            }
            console.log(address, " maniphdibvfei")
            return address;
        } else {
            return "";
        }
    };

    return !isLoading && isPageLoaded? <Grid item md={12}>
        {/**Breadcrum component starts here */}
        <PoDetailsBreadCrum />
        {/**Breadcrum component ends here */}

        <Card
            style={{
                borderRadius: '10px',
                height: '100',
                marginTop: '5px',
                marginLeft: '10px',
                marginRight: '10px'
            }}
        >
            <CardContent>
                <Grid container spacing={3}>
                    <div style={{
                        width: '100%',
                        display: 'flex'
                    }}>
                        <Grid item xs={3} style={{
                            height: '50px'
                        }}>
                            <List>
                                <ListItem>
                                    <Typography variant="h3" className={acimaDeliveryClasses.deliverytype + " " + classes.mb0}>PO # :</Typography>
                                    <Typography className={classes.formLabel + " " + classes.fontGreyBlue + " " + classes.p1 + " " + classes.mb0}   >{data?.purchaseOrderNumber}</Typography>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={3} style={{
                            height: '50px'
                        }}>
                            <List>
                                <ListItem>
                                    <Typography variant="h3" className={acimaDeliveryClasses.deliverytype + " " + classes.mb0}>PO Created Date:</Typography>
                                    <Typography className={classes.formLabel + " " + classes.fontGreyBlue + " " + classes.p1 + " " + classes.mb0} >
                                        {data?.createdDate ? moment(data?.createdDate).format('MM/DD/YYYY') : ''}
                                    </Typography>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={3} style={{
                            height: '50px'
                        }}>
                            <List>
                                <ListItem>
                                    <Typography variant="h3" className={acimaDeliveryClasses.deliverytype + " " + classes.mb0}>Vendor Name:</Typography>
                                    <Typography className={classes.formLabel + " " + classes.fontGreyBlue + " " + classes.p1 + " " + classes.mb0}  >{data?.vendorName}</Typography>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={3} style={{
                            height: '50px'
                        }}>
                            <List>
                                <ListItem>
                                    <Typography variant="h3" className={acimaDeliveryClasses.deliverytype + " " + classes.mb0}>Total Cost:</Typography>
                                    <Typography className={classes.formLabel + " " + classes.fontGreyBlue + " " + classes.p1 + " " + classes.mb0} >${data?.totalCost}</Typography>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={3} style={{
                            height: '50px'
                        }}>
                            <List>
                                <ListItem>
                                    <Typography variant="h3" className={acimaDeliveryClasses.deliverytype + " " + classes.mb0}>PO Status:</Typography>
                                    <Typography className={classes.formLabel + " " + classes.fontGreyBlue + " " + classes.p1 + " " + classes.mb0} >{data?.poStatus.toLowerCase() == "pending" || data?.poStatus.toLowerCase() == "approved-released to store" ? "Pending" : data?.poStatus}</Typography>
                                </ListItem>
                            </List>
                        </Grid>
                    </div>
                    <div style={{
                        width: '100%',
                        display: 'flex'
                    }}>
                        <Grid item xs={3} style={{
                            height: '50px',
                            marginBottom: '10px',
                            maxWidth: '20%',
                            flexBasis: '20%'
                        }}>
                            <List>
                                <ListItem>
                                    <Typography variant="h3" className={acimaDeliveryClasses.deliverytype + " " + classes.mb0}>Customer Name:</Typography>
                                    <Typography className={classes.formLabel + " " + classes.fontGreyBlue + " " + classes.p1 + " " + classes.mb0}>{data?.customerName}</Typography>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={3} style={{
                            height: '50px',
                            marginBottom: '10px',
                            maxWidth: '20%',
                            flexBasis: '20%'
                        }}>
                            <List>
                                <ListItem>
                                    <Typography variant="h3" className={acimaDeliveryClasses.deliverytype + " " + classes.mb0}>Phone #:</Typography>
                                    <Typography className={classes.formLabel + " " + classes.fontGreyBlue + " " + classes.p1 + " " + classes.mb0} >{
                                        `(${data?.phoneNumber?.split("-").join("").slice(0, 3)}) ${data?.phoneNumber?.split("-").join("").slice(3, 6)}-${data?.phoneNumber?.split("-").join("").slice(6, 10)}`
                                    }
                                    </Typography>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={3} style={{
                            height: '50px',
                            marginBottom: '10px',
                            maxWidth: '20%',
                            flexBasis: '20%'
                        }}>
                            <List>
                                <ListItem>
                                    <Typography variant="h3" className={acimaDeliveryClasses.deliverytype + " " + classes.mb0}>Email ID:</Typography>
                                    <Typography className={classes.formLabel + " " + classes.fontGreyBlue + " " + classes.p1 + " " + classes.mb0} >
                                        <span title={data?.email}>
                                        {data?.email.length >= 27
                                            ? data?.email.substring(0, 25) + '...'
                                            : data?.email}
                                            </span>
                                    </Typography>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={3} style={{
                            height: '50px',
                            marginBottom: '10px',
                            maxWidth: '40%',
                            flexBasis: '40%'
                        }}>
                            <List>
                                <ListItem>
                                    <Typography variant="h3" className={acimaDeliveryClasses.deliverytype + " " + classes.mb0}>Address:</Typography>
                                    <Typography  className={classes.formLabel + " " + classes.fontGreyBlue + " " + classes.p1 + " " + classes.mb0}>
                                        <span title={data.address}>{
                                        manipulateAddress(data.address).length > 60
                                            ? manipulateAddress(data.address).substring(0, 60) + '...'
                                            : manipulateAddress(data.address)
                                    }</span></Typography>
                                </ListItem>
                            </List>
                        </Grid>
                    </div>
                </Grid>
            </CardContent>
        </Card>
    </Grid> : null
}