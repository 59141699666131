/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles, RACCOLOR } from "@rentacenter/racstrap";
export const reversePaymentStyle = () => {
  const useClasses = makeStyles((theme) => ({
    "@global": {
      "*::-webkit-scrollbar": {
        width: "10px",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(189, 189, 196)",
        borderRadius: "20px",
        border: "3px solid rgb(206, 204, 201)",
      },
      "*::-webkit-scrollbar-track": {
        background: "rgb(245, 243, 239)",
      },
    },
    "& body": {
      backgroundColor: "#ECF0F3",
    },
    containerSpacing: {
      paddingLeft: "10px",
      paddingRight: "10px",
      marginBottom: "120px",
    },
    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
      width: "100%",
    },
    w100: {
      width: "100%",
    },
    floatLeft: {
      float: "left",
    },
    alertwidget: {
      borderRadius: "5",
    },
    px0: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
    },
    mt8: {
      marginTop: "6%",
    },
    h65: {
      height: "65px",
    },
    paymentAlignCenter: {
      display: "flex",
      alignItems: "center",
      // justifyContent: 'center',
    },
    raccollg5: {
      // flex: '0 0 auto',
      width: "100%",
    },
    raccollg6: {
      flex: "0 0 auto",
      width: "15%",
    },
    titletxtstyle: {
      fontSize: theme.typography.pxToRem(18),
    },
    bold: {
      fontFamily: "OpenSans-bold",
    },
    pe4: {
      paddingRight: theme.typography.pxToRem(24),
    },
    raccollg3: {
      flex: "0 0 auto",
      width: "10%",
    },
    raccollg3CustmerName: {
      flex: "0 0 auto",
      width: "15%",
    },
    labeltxtstyle: {
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
      color: "#000000",
    },
    labelbluetxtstyle: {
      color: "#2179FE",
      textDecoration: "none",
      fontFamily: "OpenSans-semibold",
    },
    px1: {
      paddingRight: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(4),
    },
    labelnotxtstyle: {
      color: "#6C86A5",
    },
    mb100: {
      marginBottom: "120px",
    },
    agrementGridMC: {
      "& > tbody > tr": {
        background: "transparent",
      },
      "& td": {
        padding: "2px 2px",
      },
    },

    labelColorForSelect : {
      "color":"#646B88"
    },

    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    textCenter: {
      textAlign: "center",
    },
    emptyWidth: {
      width: "5%",
    },
    actionWidth: {
      width: "15%",
    },
    breadcrumbItemActive: {
      // width: "auto",
      fontFamily: "OpenSans-bold",
      fontSize: "14px",
      color: "#3182FF",
    },
    customerTablecellbgcolor: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    cusomerInfo: {
      borderLeft: "7px solid #7bbffc",
      boxShadow: "0 1px 25px 0 rgb(0 0 0 / 7%)",
    },
    cusomerInfoTab: {
      borderLeft: "7px solid #7bbffc",
      // boxShadow: '0 1px 25px 0 rgb(0 0 0 / 7%)',
    },
    customerAccordianopen: {
      borderLeft: "5px solid #7bbffc",
      boxShadow: "0px -1px 2px 0px #eaeff5",
      borderTopLeftRadius: theme.typography.pxToRem(12),
      borderTopRightRadius: theme.typography.pxToRem(12),
    },
    customerHiddenrow: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    spacerP0: {
      padding: theme.typography.pxToRem(0),
    },
    agreementGridInnerRow: {
      marginLeft: "-1.5px",
      boxShadow: "4px 4px 4px #eaeaea",
      borderLeft: "5px solid #7bbffc",
      paddingBottom: "2rem",
      marginBottom: "2rem",
    },
    agreementGrid: {
      width: "94%",
      margin: "0 auto !important",
      borderCollapse: "separate",
      borderSpacing: "0 5px !important",
    },
    chkBoxAlign: {
      textAlign: "end",
    },
    dueAlign: {
      textAlign: "center",
    },
    fixedBottom: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      padding: "1rem",
    },
    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    floatRight: {
      float: "right",
    },
    rcptButton: {
      color: "#0089ff",
      backgroundColor: "#e5efff",
      fontFamily: "OpenSans-bold",
      padding: "0.325rem 1.075rem",
      "&:hover": {
        color: "white",
      },
    },
    justifyContentCenter: {
      justifyContent: "center",
    },
    justifyContentRight: {
      justifyContent: "right",
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },
    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },
    p0: {
      padding: "2% 5% !important",
    },
    notesLabel: {
      color: "#212529",
      // padding: '0px 0px 10px',
    },
    fieldVisitTextContent: {
      borderRadius: "5px",
      width: theme.typography.pxToRem(450),
    },
    reqField: {
      color: "red",
    },
    gridLabel: {
      color: "#6C86A5",
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(14),
    },
    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },
    cursorPointer: {
      cursor: "pointer",
    },
    cursorDisable: {
      cursor: "not-allowed",
    },
    cursorDisableEvent: {
      pointerEvents: "none",
    },
    paymentmodal: {
      width: "100% !important",
      maxWidth: "100% !important",
      maxHeight: "100% !important",
      height: "100% !important",
    },
    textcenter: {
      textAlign: "center",
    },
    textend: {
      textAlign: "right",
    },
    customModal: {
      "& div": {
        "& div": {
          maxWidth: "100% !important",
          maxHeight: "100% !important",
        },
      },
    },
    py4: {
      paddingTop: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(24),
    },
    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    margincard: {
      margin: "18px 10px",
      display: "flex",
      flexWrap: "wrap",
    },
    racSkyBlue: {
      backgroundColor: "#EFF4FF",
    },
    bluecards: {
      boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
      width: "100%",
      padding: "10px 10px",
      backgroundColor: "#EFF4FF",
    },
    modalfooter: {
      borderTop: "0px solid transparent",
    },
    pt3: {
      paddingTop: "3%",
    },
    cctHeading: {
      paddingLeft: "0.5%",
      float: "left",
      paddingTop: "3%",
      color: "black",
      fontSize: "18px",
      fontFamily: "opensans-bold",
    },
    cardPayLabel: {
      color: "#4A5174",
      fontFamily: "OpenSans-bold",
    },
    cardPayAmtLabel: {
      fontFamily: "OpenSans-bold",
      fontSize: "14px",
      float: "right",
      paddingLeft: "13px",
    },
    cardPayCancelBtn: {
      backgroundColor: "white",
      color: "#2C3333",
    },
    w20: {
      width: "20%",
    },
    colmd12: {
      flex: "0 0 auto",
      width: "100%",
    },
    me3: {
      marginRight: theme.typography.pxToRem(16),
    },
    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    popUpTxtStyle: {
      fontFamily: "OpenSans-regular",
      fontSize: "16px",
    },
    ms1: {
      marginLeft: theme.typography.pxToRem(4),
    },
    alertBackground: {
      backgroundColor: "white !important",
      fontFamily: "OpenSans-bold !important",
      borderRadius: "15px !important",
      marginBottom: "1% !important",
    },
    mb1p: {
      marginBottom: "1%",
    },
    hideAlert: {
      display: "none !important",
    },
    disablePage: {
      pointerEvents: "none",
    },
    ps2: {
      paddingLeft: theme.typography.pxToRem(8),
    },
    RAClabeltxtstyle: {
      fontSize: theme.typography.pxToRem(14),
      marginBottom: theme.typography.pxToRem(5),
      fontFamily: "OpenSans-semibold",
      color: "#111111",
    },
    semibold: {
      fontFamily: "OpenSans-semibold",
    },
    RACvaluetxtstyle: {
      color: "#B91919",
      fontSize: theme.typography.pxToRem(14),
    },
    formLabel: {
      marginBottom: "0.2rem",
      color: "#111111",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
    },
    p3: {
      padding: theme.typography.pxToRem(16),
    },
    textright: {
      textAlign: "right",
    },
    paymentSmallBtn1: {
      padding: "6px 10px",
    },
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useClasses();
};
