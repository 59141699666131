/* eslint-disable */

import { Card, CardContent, Grid, RACButton, Typography, RACModalCard } from "@rentacenter/racstrap";
import React, { useContext, useEffect, useState } from "react";
import { PoDetailsContext } from "./context/PoDetailsContext";
import { agreementDeliveryStyles } from "./PoDetailStyles/agreementDeliveryStyles";
import { agreementGlobalStyles } from "./PoDetailStyles/agreementGlobalStyles";
import { useHistory } from "react-router-dom";
import { postPoReceive } from "../../api/user";

export const PoDetailsFooter = () => {

    /**CSS Imports starts here */
    const acimaDeliveryClasses = agreementDeliveryStyles();
    const classes = agreementGlobalStyles();
    /**CSS Imports ends here */

    /**useContext data stats here */
    const { data, setData, nonReceivedItems, setNonReceivedItems, selectedItem, setSelectedItem, selectReceivedItem, setSelectReceivedItem, getLtoData, somethingWentWrongOpen, setSomethingWentWrongOpen, somethingWentWrongOpenPop, setSomethingWentWrongOpenPop, errorMessagePopupOpenPop, setErrorMessagePopupOpenPop, manageAgrErrMessage, setManageAgrErrMessage, isHiddenLoader, setIsHiddenLoader,somethingMessage,setSomethingMessage,reverseArray, setReverseArray,
        selectedReverseItem, setSelectedReverseItem,
        isReversePoOpen, setIsReversePoOpen,isPrintPoOpen, setIsPrintPoOpen } = useContext(PoDetailsContext);
    const [isReceiveDisabled, setIsReceiveDisabled] = useState(true);
    const [doYouWantSchedulePopupIsOpen, setDoYouWantSchedulePopupIsOpen] = useState(false);

    const history = useHistory();
    /**useContext data ends here */

    /**useEffect starts here */
    useEffect(() => {
        isReceivable();
    }, [selectedItem, nonReceivedItems, selectReceivedItem]);
    /**useEffect ends here */



    /**Functions starts here */
    const receiveButtonClick = async () => {
        try {
            setIsHiddenLoader(true);
            const nonReceivedItemsSpread = [...nonReceivedItems];
            const receivePayload: any = {
                "purchaseOrderId": String(data?.purchaseOrderId),
                "purchaseOrderNumber": String(data?.purchaseOrderNumber),
                "itemInformation": []
            }
            for (const values of nonReceivedItemsSpread) {
                if (selectedItem.includes(values["poDetailReceivedId"])) {
                    const today = new Date();
                    const year = today.getFullYear();
                    const month = String(today.getMonth() + 1).padStart(2, '0');
                    const day = String(today.getDate()).padStart(2, '0');
                    const formattedDate = `${year}-${month}-${day}`;
                    let jsonData: any = {
                        "poDetailReceivedId": (values?.isNew) ? null : String(values?.poDetailReceivedId),
                        "purchaseOrderDetailId": String(values?.purchaseOrderDetailId),
                        "receivingNow": (values?.receivingNow == 'All' || values?.receivingNow == 'ALL' || values?.receivingNow == 'all') ? 'ALL' : 'PARTIAL',
                        "bol": values?.bol,
                        "dateReceived": formattedDate
                    }

                    if (values?.receivingNow !== 'All') {
                        jsonData.comments = values?.comments;
                        jsonData.partialReceiveReason = values?.partialReason;
                    }

                    receivePayload.itemInformation.push(jsonData);
                }
            }


            let needToOpen = receivePayload.itemInformation.some((value: any, index: any) => {return (value.receivingNow == "ALL")})
           

            let filterItem = nonReceivedItems.filter((value:any,index:any) => {
                 return (value.itemStatus == "Received");
             }).map((value:any,index:any) => {
                 return value.poDetailReceivedId
             })
           

            console.log(receivePayload, "This is the request payload!!!")
            let postPoReceiveApiCall = await postPoReceive(receivePayload);

            if (postPoReceiveApiCall?.status == 500) {
                setIsHiddenLoader(false);
                setSomethingMessage("Unable to receive the items");
                setSomethingWentWrongOpenPop(true);
                return;
              }
            console.log(postPoReceiveApiCall.data, "This is the api call for receive");
            const nonReceivedItemsNewSpread = [...nonReceivedItems];
            let memoryOfReceivedIds = nonReceivedItemsNewSpread.filter(value => value.itemStatus === "Received").map(value => value.poDetailReceivedId);
            console.log(memoryOfReceivedIds,"before the response");

            let dataAfterUpdate = await getLtoData();
            setIsHiddenLoader(false);
            // if (dataAfterUpdate.itemInformation.some((value: any, index: any) => {
            //     return (
            //         value.itemStatus == "Received"
            //     )
            // })) {
            //     setDoYouWantSchedulePopupIsOpen(true);
            //     let receivedItems: any = [];
            //     for (let values of dataAfterUpdate.itemInformation) {
            //         if (values.itemStatus == "Received") {
            //             receivedItems.push(values.poDetailReceivedId)
            //         }
            //     }
            //     setSelectReceivedItem(receivedItems);
            // }

            let newFiler = dataAfterUpdate.itemInformation.filter((value:any,index:any) => {
                return (value.itemStatus == "Received");
            }).map((value:any,index:any) => {
                return value.poDetailReceivedId
            })
            console.info("🚀 ~ file: PoDetailsFooter.tsx:115 ~ newFiler ~ newFiler:", newFiler)
            console.info("🚀 ~ file: PoDetailsFooter.tsx:78 ~ filterItem ~ filterItem:", filterItem)
            console.info("🚀 ~ file: PoDetailsFooter.tsx:72 ~ receiveButtonClick ~ needToOpen:", needToOpen)
            
            if(needToOpen){
                setDoYouWantSchedulePopupIsOpen(true);
                const itemsOnlyInArray2 = newFiler.filter(item => !filterItem.includes(item));
                console.log("🚀 ~ file: PoDetailsFooter.tsx:120 ~ receiveButtonClick ~ itemsOnlyInArray2:", itemsOnlyInArray2)
                setSelectReceivedItem(itemsOnlyInArray2);
            }


            setSelectedItem([]);
        } catch (error: any) {
            console.log(error.message);
        }
    }

    {/* Do you want to schedule popup*/ }

    const schedulePopup = () => {
        return (
            <Grid style={{ "textAlign": "center" }} className={classes.p4}>
                <Typography className={classes.font24}
                            variant="h6" style={{
                            textAlign:"center",
                              "fontWeight": "normal",
                              color: "#393939",
                              fontSize: "17px",
                              fontFamily : 'OpenSans-semibold',
                              maxWidth: "100%",
                              alignContent: "center"
                          }}>Do you want to schedule delivery for the received items ?</Typography>
                <Grid style={{ "textAlign": "center" }}>
                    <RACButton
                        color="primary"
                        variant="outlined"
                        size="small"
                        className={classes.mt4 + " " + classes.p2 + " " + classes.mx2 + " " + classes.px4 + " "}
                        onClick={() => {
                            setDoYouWantSchedulePopupIsOpen(false)
                            setSelectReceivedItem([])
                        }}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.mt4 + " " + classes.p2 + " " + classes.mx2 + " " + classes.px4 + " "}
                        onClick={() => {
                            setDoYouWantSchedulePopupIsOpen(false)
                            setSomethingWentWrongOpen(true);
                        }}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        )
    }
    {/* Do you want to schedule popup ends here*/ }


    //! the following function will be used to check the enable status of the button;
    const isReceivable = () => {
        if (selectedItem.length === 0 || selectReceivedItem.length !== 0) {
            setIsReceiveDisabled(true)
            return;
        } else {
            console.log(nonReceivedItems, "this is the non received items!!!");
            for (const values of nonReceivedItems) {
                // console.log(selectedItem,values.itemNo,"this is the selected item!!!",selectedItem.includes(values.itemNo),);
                if (selectedItem.includes(values.poDetailReceivedId) && (values.bol == "" || values.bol == null)) {
                    setIsReceiveDisabled(true)
                    return;
                }
            }
            setIsReceiveDisabled(false);
        }
        return;
    }

    /**Functions ends here */



    /**Error popup starts here */
    const errorMessagePopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    {/* <Alerticon></Alerticon> */}
                    <Typography className={classes.formLabel}>
                        {manageAgrErrMessage}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setErrorMessagePopupOpenPop(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </div>
        );
    };
    const somethingWentWrongPopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    {/* <Alerticon></Alerticon> */}
                    <Typography className={classes.formLabel}>
                        {somethingMessage}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSomethingWentWrongOpenPop(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </div>
        );
    };
    /**Error popup ends here */

    return (
        <>
            {/**do you want schedule popup starts here */}
            <RACModalCard
                isOpen={doYouWantSchedulePopupIsOpen}
                closeIcon={false}
                maxWidth="xs"
                borderRadius={"20px"}
                onClose={() => {
                    setDoYouWantSchedulePopupIsOpen(false)
                    setSelectReceivedItem([]);
                }
                }
            >
                {schedulePopup()}
            </RACModalCard>
            {/**do you want schedule popup starts here */}
            {/**Something went wrong popup */}
            <RACModalCard
                isOpen={somethingWentWrongOpenPop}
                closeIcon={false}
                maxWidth="xs"
                borderRadius={"15px"}
            >
                {somethingWentWrongPopup()}
            </RACModalCard>
            <RACModalCard
                isOpen={errorMessagePopupOpenPop}
                closeIcon={false}
                maxWidth="xs"
                borderRadius={"15px"}
            >
                {errorMessagePopup()}
            </RACModalCard>
            {/**Something went wrong popup */}
            <Grid item md={12} style={{ position: "fixed", bottom: "0px", width: "100%" }}>
                <Card>
                    <CardContent>
                        <RACButton
                            variant='contained'
                            color="primary"
                            size="small"
                            className={acimaDeliveryClasses.footerRight}
                            disabled={isReceiveDisabled}
                            onClick={() => {
                                receiveButtonClick();
                            }}
                        >
                            Receive
                        </RACButton>
                        <RACButton
                            // variant="contained"
                            color="primary"
                            variant='contained'
                            size="small"
                            className={acimaDeliveryClasses.footerRight}
                            disabled={
                                selectedItem.length !== 0 || selectReceivedItem.length == 0
                            }
                            onClick={() => { setSomethingWentWrongOpen(true) }}
                        >
                            Schedule
                        </RACButton>
                        <RACButton
                            color="primary"
                            variant='contained'
                            size="small"
                            className={acimaDeliveryClasses.footerRight}
                            disabled={
                                reverseArray.length == 0
                            }
                            onClick={() => {
                                setIsReversePoOpen(true)
                            }}
                        >
                            Reverse PO
                        </RACButton>
                        {/* <RACButton
                            color="primary"
                            variant='contained'
                            size="small"
                            className={acimaDeliveryClasses.footerRight}
                            disabled
                        // disabled={cancelPO}
                        // onClick={() => {
                        //     setCancelPoIsOpen(true);
                        // }}
                        >
                            Cancel PO
                        </RACButton> */}
                        <RACButton
                            variant="contained"
                            color="primary"
                            size="small"
                            className={acimaDeliveryClasses.footerRight}
                            disabled={
                                reverseArray.length == 0
                            }
                            onClick = {
                                () =>{
                                    setIsPrintPoOpen(true);
                                }
                            }
                        >
                            Print
                        </RACButton>
                        <RACButton
                            variant='outlined'
                            color="primary"
                            size="small"
                            className={acimaDeliveryClasses.footerLeft}
                            onClick={() => {
                                history.push('/acimapo/poreceive')
                            }}
                        >
                            Cancel
                        </RACButton>
                    </CardContent>
                </Card>
            </Grid>
        </>

    )
}