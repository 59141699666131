/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */

import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const agreementDeliveryStyles = () => {
  const useClasses = makeStyles((theme) => ({
    title: {
      fontSize: theme.typography.pxToRem(16),
      fontFamily: 'Opensans-bold',
      marginTop: theme.typography.pxToRem(12),
      marginBottom: theme.typography.pxToRem(12),
    },
    footerLeft: {

      backgroundColor: "white",

      textTransform: "none",

      fontWeight: "bolder",

      paddingLeft: "18px",

      paddingRight: "18px",

      paddingBottom: "9px",

      paddingTop: "9px",

      color: "2179FE",

      margin: " 0px 0px 20px 20px",

      float: "left"

  },
  footerRight: {

      backgroundColor: "#2179FE",

      textTransform: "none",

      fontWeight: "bolder",

      paddingLeft: "18px",

      paddingRight: "18px",

      paddingBottom: "9px",

      paddingTop: "9px",

      color: "white",

      margin: " 0px 0px 20px 20px",

      float: "right"

  },
    subTitle: {
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      fontFamily: 'Opensans-semibold',
    },
    tabPanel: {
      paddingLeft: theme.typography.pxToRem(10),
      paddingRight: theme.typography.pxToRem(10),
      marginBottom: '120px',
    },
    card: {
      borderRadius: '15px',
    },
    DetailsPadding: {
      marginBottom: '7px',
    },
    formCheck: {
      minHeight: '1.5rem',
      marginBottom: '0.125rem',
      display: 'inline-block',
      marginRight: '1rem',
      paddingLeft: 0,
    },
    bgdateError: {
      '& input': {
        border: 'thin solid red',
      },
    },
    dFlex: {
      display: 'flex',
    },
    widthAdjust16: {
      width: '16.66%',
      float: 'left',
      marginBottom: '1rem',
    },
    widthAdjust13: {
      width: '13.66%',
    },
    spacerMT2: {
      marginTop: theme.typography.pxToRem(16),
    },
    formLabel: {
      color: '#111111',
      fontSize: '14px',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      marginTop: '4px',
    },
    formLabelValue: {
      color: '#6e6e6e',
      fontSize: '14px',
      fontFamily: 'OpenSans-semibold',
      marginTop: '8px',
    },
    buttonAddRows: {
      padding: 0,
      border: 'none',
      background: 'none',
      color: '#2179fe',
      textTransform: 'capitalize',
      fontFamily: 'OpenSans-semibold',
      marginTop: '20px',
    },
    floatLeft: {
      float: 'left',
    },
    floatRight: {
      float: 'right',
    },
    deliveryEventDesc: {
      fontSize: '13px',
      fontFamily: 'OpenSans-regular',
      color: '#525252',
    },
    circleBusy: {
      width: '10px',
      height: '10px',
      float: 'left',
      borderRadius: '20px',
      marginTop: '4px',
      marginRight: '7px',
      background: '#D3D4D5',
    },
    circleAvailable: {
      width: '10px',
      height: '10px',
      float: 'left',
      borderRadius: '20px',
      marginTop: '4px',
      marginRight: '7px',
      background: '#2468FF',
    },
    spacerMT8: {
      marginTop: theme.typography.pxToRem(20),
    },
    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },
    me48: {
      marginRight: theme.typography.pxToRem(48),
    },
    spacerMR4: {
      marginRight: theme.typography.pxToRem(12),
    },
    spacerMB5: {
      marginBottom: theme.typography.pxToRem(24),
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '1.5rem',
      marginRight: '0.75rem',
    },
    agrListGroup: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '10px',
      marginBottom: 0,
      borderRadius: '0.25rem',
    },
    agrTimeSlot: {
      width: '14%',
      display: 'inline-block',
    },
    slotAvailable: {
      border: '1px solid #2179fe',
      color: '#2179fe',
      borderImage: 'initial',
      backgroundColor: 'hsl(0deg 0% 100%)',
      paddingRight: '11px',
      paddingBottom: '17px',
      paddingTop: '17px',
      paddingLeft: '13px',
      fontSize: '14px',
      marginBottom: '10px',
      fontFamily: 'OpenSans-semibold',
    },
    slotBooked: {
      border: '1px solid #2179fe',
      backgroundColor: '#2179fe',
      color: '#ffffff',
      paddingRight: '11px',
      paddingBottom: '17px',
      paddingTop: '17px',
      paddingLeft: '13px',
      fontSize: '14px',
      marginBottom: '10px',
      fontFamily: 'OpenSans-semibold',
    },
    slotBusy: {
      backgroundColor: '#eaeef1',
      color: ' #767676',
      paddingRight: '11px',
      paddingBottom: '17px',
      paddingTop: '17px',
      paddingLeft: '13px',
      fontSize: '14px',
      marginBottom: '10px',
      fontFamily: 'OpenSans-semibold',
      border: '0px solid',
    },
    fixedBottom: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: '#ffffff',
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      padding: '1rem',
    },
    fixTableHead: {
      overflowY: 'auto',
      maxHeight: '500px',
      '& $th': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: 'OpenSans-semibold',
        paddingBottom: 0,
      },
      '& $tr': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
      },
      '& $td': {
        fontFamily: 'OpenSans-semibold',
      },
    },
    modalFooter: {
      display: 'flex',
      flexWrap: 'wrap',
      flexShrink: 0,
      justifyContent: 'flex-end',
      padding: '0.75rem',
    },
    spacerPX2: {
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
    },
    RACPopupMsg: {
      fontSize: theme.typography.pxToRem(14),
      color: `${RACCOLOR.RICH_BLACK}`,
      fontFamily: 'Opensans-semibold',
      marginBottom: theme.typography.pxToRem(10),
    },
    validateCheck: {
      paddingLeft: 0,
      marginBottom: '10px',
    },
    dInherit: {
      display: 'inherit',
    },
    dispBlk: {
      display: 'block',
    },
    Norecords: {
      textAlign: 'center',
      marginTop: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      width: '100%',
    },
    font14: {
      fontSize: theme.typography.pxToRem(14),
    },
    semiBold: {
      fontFamily: 'OpenSans-semibold',
    },
    textCenter: {
      textAlign: 'center',
    },
    my2: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },
    py4: {
      paddingTop: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(24),
    },
    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    mt3: {
      marginTop: theme.typography.pxToRem(16),
    },
    Mandatory: {
      content: '*',
      color: 'red',
    },
    AddressPopup: {
      fontFamily: 'OpenSans-bold !important',
    },
    MarginDate: {
      marginBottom: '5px',
    },
    agrDeliveryReceiptText: {
      fontFamily: 'OpenSans-semibold',
      fontSize: '17px',
    },
    deliverytype: {
      fontFamily: 'OpenSans-bold',
      fontSize: '16px',
      color: '#404040',
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },
  }));
  const classes = useClasses();
  return classes;
};
