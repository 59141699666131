/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
import { RACCOLOR, makeStyles } from "@rentacenter/racstrap";
export const customerStyles = () => {
  const UseClasses = makeStyles((theme) => ({
    ssnBorderRed: {
      border: "1px solid red",
      borderRadius: "6px",
      outline: "none",
    },
    racGlobalSearchBtn: {
      cursor: "pointer",
      width: "25px",
      float: "left",
      paddingTop: "0.2rem",
    },
    widgetTitle: {
      fontSize: theme.typography.pxToRem(16),
      fontFamily: "OpenSans-bold",
      color: `${RACCOLOR.WASHED_BLACK}`,
    },
    spacer: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
    },
    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
      width: "100%",
    },
    customerFloatright: {
      float: "right",
    },
    customerFloatleft: {
      float: "left",
    },
    fixedBottom: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      padding: "1rem",
    },
    customerRow: {
      width: "100%",
    },
    customerJustifycontentcenter: {
      justifyContent: "center",
    },

    customerToolbar: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    spacerP0: {
      padding: theme.typography.pxToRem(0),
    },
    spacerP2: {
      padding: theme.typography.pxToRem(16),
    },
    spacerMT0: {
      marginTop: theme.typography.pxToRem(0),
    },
    spacerMB3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    spacerMT3: {
      marginTop: theme.typography.pxToRem(16),
    },
    spacerMT4: {
      marginTop: theme.typography.pxToRem(24),
    },
    spacerMB1: {
      marginBottom: theme.typography.pxToRem(4),
    },
    spacerMT1: {
      marginTop: theme.typography.pxToRem(4),
    },
    spacerMR2: {
      marginRight: theme.typography.pxToRem(8),
    },
    spacerMR1: {
      marginRight: theme.typography.pxToRem(16),
      marginTop: theme.typography.pxToRem(16),
    },
    spacerPX2: {
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
    },
    spacerMB3PX1: {
      marginBottom: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(4),
      paddingRight: theme.typography.pxToRem(4),
    },
    spacerPT1: {
      paddingTop: theme.typography.pxToRem(16),
    },
    spacerPB2: {
      paddingBottom: theme.typography.pxToRem(8),
    },
    spacerMB2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    spacerMS2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },
    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },
    customerDisplayinline: {
      display: "inline-block",
    },
    customerColmd: {
      flex: "0 0 auto",
      width: "100%",
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    sideTitle: {
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.WASHED_BLACK}`,
      marginBottom: theme.typography.pxToRem(10),
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: "OpenSans-semibold",
    },
    customerTablecellbgcolor: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    customerAccordionopen: {
      borderLeft: "2px solid #7bbffc",
      boxShadow: "0px -1px 2px 0px #eaeff5",
      borderTopLeftRadius: theme.typography.pxToRem(12),
      borderTopRightRadius: theme.typography.pxToRem(12),
    },
    accordionIconGrid: {
      backgroundPosition: "center",
      cursor: "pointer",
      width: "23px",
      height: "23px",
      display: "block",
      float: "left",
      marginTop: "1px",
    },
    customerHiddenrow: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    customerAccordianopen: {
      borderLeft: "5px solid #7bbffc",
      boxShadow: "0px -1px 2px 0px #eaeff5",
      borderTopLeftRadius: theme.typography.pxToRem(12),
      borderTopRightRadius: theme.typography.pxToRem(12),
    },
    customerCheckbox: {
      color: `${RACCOLOR.DEEP_SKY_BLUE}`,
    },
    customerRaclink: {
      color: `${RACCOLOR.BLUE_CRAYOLA}`,
      textDecoration: "none",
      fontFamily: "OpenSans-semibold",
      cursor: "pointer",
    },
    customerLinebreak: {
      whiteSpace: "nowrap",
    },
    customerPointercursor: {
      pointer: "cursor",
    },
    customerTextend: {
      textAlign: "right",
    },
    customerTextcenter: {
      textAlign: "center",
    },
    customerRacpopup: {
      fontFamily: "OpenSans-regular",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: "0px",
    },
    spacerMS1: {
      marginLeft: theme.typography.pxToRem(4),
    },
    customerLineBreak: {
      whiteSpace: "nowrap",
    },
    checkBoxwidth: {
      width: theme.typography.pxToRem(10),
    },
    customerTextsubtilegrid: {
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(18),
      color: "#000",
    },
    customerNorecords: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
    },
    customerNorecordsAlert: {
      textAlign: "center",
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      marginRight: "10px !important",
    },
    alertLoader: {
      width: "30px !important",
      height: "30px !important",
      marginTop: "10px",
    },
    formLabel: {
      marginBottom: "0.2rem",
      color: "#111111",
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
    },
    mandatoryfield: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
    },
    demooooo: {
      color: "yellow",
    },
    RACPOPMsg: {
      fontFamily: "OpenSans-regular",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    spacerME2: {
      marginRight: theme.typography.pxToRem(16),
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },
    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },
    ps2: {
      paddingLeft: theme.typography.pxToRem(8),
    },
    spacerMT2: {
      marginTop: theme.typography.pxToRem(16),
    },
    semiBold: {
      fontFamily: "OpenSans-semibold",
    },
    formCheck: {
      minHeight: "1.5rem",
      marginBottom: "0.125rem",
      display: "inline-block",
      marginRight: "1rem",
      paddingLeft: 0,
    },
    textRight: {
      textAlign: "right",
    },
    textCenterSSN: {
      "& input": {
        textAlign: "center",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    w100: {
      width: "100%",
    },
    floatLeft: {
      float: "left",
    },
    floatRight: {
      float: "right",
    },
    justifyContentCenter: {
      justifyContent: "center",
    },
    bold: {
      fontFamily: "OpenSans-bold",
    },
    font16: {
      fontSize: "16px",
    },
    customerTable: {
      "& tr": {
        backgroundColor: "transparent",
      },
    },
    paymentIssue: {
      backgroundColor: "#ffefef",
      "& td": {
        backgroundColor: "#ffefef",
      },
      "& td:first-child": {
        borderTopLeftRadius: "7px",
        borderBottomLeftRadius: "7px",
      },
      "& td:last-child": {
        borderTopRightRadius: "7px",
        borderBottomRightRadius: "7px",
      },
    },
    racpadAgrLink: {
      borderLeft: "3px solid transparent",
      paddingLeft: "10px",
      borderRadius: "2px",
    },
    racpadPaymentInActive: {
      borderColor: "#b1adac",
    },
    racstrapTablecellBgColor: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    racpadLink: {
      color: "#2468ff",
      fontSize: "14px",
      textDecoration: "none",
      cursor: "pointer",
    },
    lineBreak: {
      whiteSpace: "nowrap",
    },
    agreementRow: {
      borderBottom: "2px solid white",
    },
    RACLoaderPage: {
      textAlign: "center",
      marginTop: "250px",
      fontSize: "16px",
      marginLeft: "30px",
      marginRight: "30px",
      outline: "none",
    },
    racFixedFooterMargin: {
      marginBottom: "120px",
    },
    racGrid: {
      '& th': {
        fontFamily: 'OpenSans-semibold',
        fontSize: '14px',
        color: '#000',
      },
      '& td': {
        fontFamily: 'OpenSans-semibold',
        fontSize: '14px',
        color: '#4A5174',
      },
    },
    txtSubtileGrid: {
      fontFamily: "OpenSans-bold",
      fontSize: "18px",
      color: "#000000",
    },
    badgeContainer: {
      padding: "1px 13px",
      borderRadius: "10px",
      backgroundColor: "#ddf8ed",
      color: "#10523e",
      marginLeft: " 5px",
    },
    px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
    },
    spacerP1: {
      padding: ".375rem .75rem",
    },
    mb100: {
      marginBottom: "120px",
    },
    loaderStyle: {
      border: "none",
      backgroundColor: "transparent",
      // userFocus: 'none',
      // MozUserFocus: 'none',
      outline: "none",
    },
    ssnpadding: {
      paddingTop: "8px",
    },
    datePicker: {
      paddingTop: "3px !important",
    },
    datePickerMB: {
      marginBottom: "0px !important",
      marginTop: "4px !important",
    },
    titleColor: {
      color: "gray",
    },
    infoTextStyle: {
      color: "gray",
      fontSize: theme.typography.pxToRem(13),
      textAlign: "center",
    },
    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },
    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },
    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },
    breadcrumb: {
      display: "flex",
      flexWrap: "wrap",
      padding: 0,
      marginBottom: theme.typography.pxToRem(10),
      listStyle: "none",
      paddingLeft: 0,
    },
    breadcrumbItem: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "12px",
      marginLeft: "-8px !important",
    },
    breadcrumbItem1: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "12px",
      marginLeft: "-8px !important",
      marginRight: "-8px !important",
    },
    breadcrumbItem2: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "12px",
      marginLeft: "-8px !important",
      marginRight: "-2px !important",
    },
    breadcrumbItemActive: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "12px",
      color: "#3182FF",
    },
    breadcrumbItemInActive: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      paddingLeft: 0,
    },
    breadcrumbArrow: {
      "&:before": {
        content: '""',
        width: 0,
        height: 0,
        borderTop: "5px solid transparent",
        borderLeft: "7px solid #707070",
        borderBottom: "5px solid transparent",
        marginTop: 0,
        float: "left",
        paddingRight: "13px",
        color: "#6c757d",
        marginLeft: "-10px !important",
        marginRight: "-2px !important",
      },
    },
    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
    },
    agreementGridInnerRow: {
      marginLeft: "-1.5px",
      boxShadow: "4px 4px 4px #eaeaea",
      borderLeft: "5px solid #7bbffc",
      paddingBottom: "2rem",
      marginBottom: "2rem",
    },
    agreementGrid: {
      width: "94%",
      margin: "0 auto !important",
      borderCollapse: "separate",
      borderSpacing: "0 5px !important",
    },
    gridBorderBottom: {
      borderBottom: "1px solid #eaeef5",
    },
    accordianWidth: {
      width: "42px",
    },
    focforLinkPaymentIssue: {
      outline: "none",
    },
    racpadPaymentFails: {
      position: "relative",
      "&:before": {
        content: '""',
        height: "20px",
        width: "3px",
        background: "#fd6a63",
        position: "absolute",
        left: 0,
        top: 0,
        borderTopRightRadius: "2px",
        borderBottomRightRadius: "2px",
      },
    },
    racpadPaymentSuccess: {
      position: "relative",
      "&:before": {
        content: '""',
        height: "20px",
        width: "3px",
        background: "#56e0d8",
        position: "absolute",
        left: 0,
        top: 0,
        borderTopRightRadius: "2px",
        borderBottomRightRadius: "2px",
      },
    },
    cusomerInfo: {
      borderLeft: "7px solid #7bbffc",
      boxShadow: "0 1px 25px 0 rgb(0 0 0 / 7%)",
    },
    customerNorecordsAgr: {
      textAlign: "center",
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
    },
    colRight: {
      textAlign: "right",
    },


    hyperLink: {
      cursor: 'pointer',
      color: RACCOLOR.BLUE_CRAYOLA,
      textDecoration: 'underline',
      textDecorationColor: RACCOLOR.BLUE_CRAYOLA,
    },
    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },
    mb1: {
      marginBottom: theme.typography.pxToRem(4),
    },
    title: {
      color: '#000',
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(10),
    },
    cardTile: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
    },
    payScheduleHighlight: {
      backgroundColor: `#2179FE`,
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    cardTileStyle: {
      borderStyle: 'solid',
      borderWidth: 'thin',
      borderColor: '#22a3ff',
    },
    justifyLabel: {
      justifyContent: 'center',
    },
    paySchedulePeriodHighlight: {
      color: `${RACCOLOR.WHITE}`,
      fontSize: '15px',
      textAlign: 'center',
    },
    paySchedulePeriodNotHighlight: {
      textAlign: 'center',
      fontSize: '15px',
      color: '#22a3ff',
    },
    alertwidget: {
      borderRadius: '15px !important',
      background: '#eff4ff',
    },
    mb1p: {
      marginBottom: '1%',
    },

    px0: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    h65: {
      height: '65px',
    },
    paymentAlignCenter: {
      display: 'flex',
      alignItems: 'center',
    },

    raccollg6: {
      flex: '0 0 auto',
      width: '15%',
    },
    titletxtstyle: {
      fontSize: theme.typography.pxToRem(18),
    },
    pe4: {
      paddingRight: theme.typography.pxToRem(24),
    },
    px20: {
      padding: 10,
    },
    payScheduleNotHighlight: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    gridLabel: {
      color: '#6C86A5',
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(14),
    },
    Pl15:{
      paddingLeft: '15Px',
    },
    Pl25:{
      paddingLeft: '25Px',
    }
  }));
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = UseClasses();
  return classes;
};
