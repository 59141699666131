/* eslint-disable prettier/prettier */
import { CircularProgress, Grid } from "@rentacenter/racstrap"
import React, { useContext } from "react"
import { PoDetailsContext } from "./context/PoDetailsContext"
export const PoDetailsLoader = () => {
    const { isLoading } = useContext(PoDetailsContext)
    return isLoading ?
        <Grid container
            justifyContent="center"  // Center horizontally
            alignItems="center"      // Center vertically
            style={{ minHeight: '100vh' }} >
            <CircularProgress />
        </Grid > : <></>
}