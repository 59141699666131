/* eslint-disable */

import React, { useContext, useEffect, useState } from "react";
import { agreementGlobalStyles } from "./PoDetailStyles/agreementGlobalStyles";
import { Grid, RACCheckBox, RACTable, RACTableCell, RACTableRow, RACModalCard, RACButton } from "@rentacenter/racstrap"
import { PoDetailsContext } from "./context/PoDetailsContext";
import html2pdf from 'html2pdf.js';
import { ReactComponent as AcimaLogo } from '../../assets/images/Acima Logo.svg'


export const PrintInformation = () => {

    const classes = agreementGlobalStyles();
    const { isPrintPoOpen, setIsPrintPoOpen, data, reverseArray } = useContext(PoDetailsContext);
    const [base64File, setBase64File] = useState("");
    const storeData: any = window.sessionStorage.getItem("storeDetails");
    const [storeDetails, setStoreDetails] = useState(
        JSON.parse(atob(storeData))
    )





    function printComponent() {
        return (
            <div style={{ width: "96%", float: "left", margin: "2%" }} id="printContainer">
                <div style={{ width: "100%", float: "left", }}>
                    <div style={{ width: "15%", float: "left" }}> <AcimaLogo /></div>
                    <h2 style={{ width: "85%", float: "left" }}>PO RECEIVING</h2>
                </div>
                <div style={{ width: "100%", float: "left", borderBottom: "2px solid #ececec", paddingBottom: "20px" }}>
                    <div style={{ width: "33.33333%", float: "left" }}>
                        <h3>RAC Store</h3>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                storeDetails?.addressLine1
                            }
                        </p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                storeDetails?.addressLine2
                            }
                        </p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                storeDetails?.city + ', ' + storeDetails?.state + ', ' + storeDetails?.zip
                            }
                        </p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                storeDetails?.workPhoneNumber
                            }
                        </p>
                    </div>
                    <div style={{ width: "33.33333%", float: "left" }}>
                        <h3>Customer</h3>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                data?.customerName
                            }
                        </p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                data?.address?.split(',')[0]
                            }
                        </p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>{
                                data?.address?.split(',')[1]
                            }</p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>{
                                // data?.city + ', ' + data?.state + ', ' + data?.zip
                            }</p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>{
                                `(${data?.phoneNumber?.split("-").join("").slice(0, 3)}) ${data?.phoneNumber?.split("-").join("").slice(3, 6)}-${data?.phoneNumber?.split("-").join("").slice(6, 10)}`
                            }</p>
                    </div>
                    <div style={{ width: "33.33333%", float: "right" }}>
                        <p>
                            <span style={{ "fontWeight": "bold" }}>PO #:</span><span style={{
                                fontFamily: 'arial',
                                fontWeight: 600,
                                lineHeight: '1.3',
                                color: '#4A5174',
                                fontSize: 12
                            }}> {' ' + data?.purchaseOrderNumber}</span>
                        </p>
                        <p><span style={{ "fontWeight": "bold" }}>Created Date #:</span><span
                            style={{
                                fontFamily: 'arial',
                                fontWeight: 600,
                                lineHeight: '1.3',
                                color: '#4A5174',
                                fontSize: 12
                            }}>{' ' + data?.createdDate}</span></p>
                    </div>
                </div>
                <div style={{ width: "100%", float: "left" }}>
                    <h3 style={{ "textAlign": "center", margin: "15px 0px" }}>ITEMS</h3>
                </div>
                <div style={{ width: "100%", float: "left" }}>
                    <table style={{ "width": "100%", float: "left", borderCollapse: "collapse", borderBottom: "2px solid #ececec" }}>
                        <thead>
                            <tr style={{ backgroundColor: "#eff4ff", textAlign: "left" }}>
                                <th style={{ padding: "15px 0px" }}>Model #</th>
                                <th style={{ padding: "15px 0px" }}>Description</th>
                                <th style={{ padding: "15px 0px" }}>Brand</th>
                                <th style={{ padding: "15px 0px" }}>Cost</th>
                                <th style={{ padding: "15px 0px" }}>Receiving Now</th>
                                <th style={{ padding: "15px 0px" }}>Comments</th>
                                <th style={{ padding: "15px 0px" }}>Bol</th>
                                <th style={{ padding: "15px 0px" }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reverseArray.map((value: any, index: any) => {
                                    return (
                                        <tr>
                                            <td style={{ padding: "15px 10px", color: '#4A5174' }}>{(value.model) ? value.model : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.description) ?value.description : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.brand) ? value.brand : "-"}</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.itemCost) ? value.itemCost : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value["receivingNow"]) ? value["receivingNow"] : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.comments) ?value.comments : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.bol) ? value.bol : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.itemStatus) ? (value.itemStatus == "Completed") ? "Delivered" : (value.itemStatus == "ToDo")  ? "Scheduled" : (value.itemStatus == "Received") ? "Received"  : null   : "-" }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    const b64toBlob = (b64Data: any, contentType: any, sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays: any = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    };

    const printButtonClick = async () => {
        debugger;
        console.info('I am called from print')
        let deliveryReceiptElement: any = document.getElementById('printContainerHidden');

        /**New  code  */
        // Calculate the new width and height for the rotated content
        //   const originalWidth = deliveryReceiptElement.offsetWidth;
        //   const originalHeight = deliveryReceiptElement.offsetHeight;
        //   const newWidth = originalHeight;
        //   const newHeight = originalWidth;
        //   // Set the width and height to fit the rotated content
        //   deliveryReceiptElement.style.width = newWidth + "px";
        //   deliveryReceiptElement.style.height = newHeight + "px";
        //   // Apply the rotation
        //   deliveryReceiptElement.style.transform = "rotate(90deg)";
        //   // Wait for a short delay to ensure the element is properly rendered after rotation
        //   await new Promise(resolve => setTimeout(resolve, 100));
        /**New Code End */

        // deliveryReceiptElement.style.transform = "rotate(90deg)";
        console.log(deliveryReceiptElement, "There is development")
        let b64DeliveryReceipt;
        html2pdf().from(deliveryReceiptElement).outputPdf('blob').then((pdfBlob) => {
            const DRreader: any = new FileReader();
            DRreader.onloadend = async () => {
                b64DeliveryReceipt = DRreader?.result.split(',')[1];
                setBase64File(b64DeliveryReceipt)
            }
            DRreader.readAsDataURL(pdfBlob);
        }
        )
        console.log(await b64DeliveryReceipt, "This is the baser")
    }

    useEffect(() => {
        let printDiv = document.getElementById("printContainerHidden");
        if (isPrintPoOpen && printDiv) {
            printButtonClick();
        }
    }, [isPrintPoOpen]);

    const viewPDFclick = () => {
        const blobResult = b64toBlob(base64File, `application/pdf`);
        const fileURL = URL.createObjectURL(blobResult);
        window.open(fileURL);
    };
    {
        console.info(base64File, "this is the base64 file")
    }
    const printPoGrid = () => {
        return (
            <>
                <Grid container>
                    <Grid item md={12}>
                        {printComponent()}
                    </Grid>
                    <Grid item md={12}>
                        <RACButton
                            color="primary"
                            variant='contained'
                            size="medium"
                            className={classes.mt4 + " " + classes.floatRight + " " + classes.py2 + " " + classes.ms2 + " " + classes.px3 + " " + classes.me4 + " " + classes.px4}
                            onClick={() => {
                                viewPDFclick()
                            }}
                        >
                            OK
                        </RACButton>
                        <RACButton
                            color="primary"
                            variant="outlined"
                            size="medium"
                            className={classes.mt4 + " " + classes.floatRight + " " + classes.p2 + " " + classes.mx2 + " " + classes.px4 + " "}
                            onClick={() => {
                                setIsPrintPoOpen(false)
                            }}
                        >
                            Cancel
                        </RACButton>
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <>
            <RACModalCard
                isOpen={isPrintPoOpen}
                closeIcon={false}
                maxWidth="lg"
                borderRadius={"15px"}
                onClose={() => {
                }}
                TitleClassName={classes.textCenter}
            >

                {printPoGrid()}
            </RACModalCard>
            {/* Hidden element for the print */}
            <div hidden>
            <div style={{ width: "96%", float: "left", margin: "2%" }} id="printContainerHidden">
                <div style={{ width: "100%", float: "left", }}>
                    <div style={{ width: "30%", float: "left" }}> <AcimaLogo /></div>
                    <h2 style={{ width: "70%", float: "left" }}>PO RECEIVING</h2>
                </div>
                <div style={{ width: "100%", float: "left", borderBottom: "2px solid #ececec", paddingBottom: "20px" }}>
                    <div style={{ width: "33.33333%", float: "left" }}>
                        <h3>RAC Store</h3>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                storeDetails?.addressLine1
                            }
                        </p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                storeDetails?.addressLine2
                            }
                        </p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                storeDetails?.city + ', ' + storeDetails?.state + ', ' + storeDetails?.zip
                            }
                        </p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                storeDetails?.workPhoneNumber
                            }
                        </p>
                    </div>
                    <div style={{ width: "33.33333%", float: "left" }}>
                        <h3>Customer</h3>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                data?.customerName
                            }
                        </p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>
                            {
                                data?.address?.split(',')[0]
                            }
                        </p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>{
                                data?.address?.split(',')[1]
                            }</p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>{
                                // data?.city + ', ' + data?.state + ', ' + data?.zip
                            }</p>
                        <p style={{
                            fontFamily: 'arial',
                            fontWeight: 600,
                            lineHeight: '1.3',
                            color: '#4A5174',
                            fontSize: 12,
                        }}>{
                                `(${data?.phoneNumber?.split("-").join("").slice(0, 3)}) ${data?.phoneNumber?.split("-").join("").slice(3, 6)}-${data?.phoneNumber?.split("-").join("").slice(6, 10)}`
                            }</p>
                    </div>
                    <div style={{ width: "33.33333%", float: "right" }}>
                        <p>
                            <span style={{ "fontWeight": "bold" }}>PO #:</span><span style={{
                                fontFamily: 'arial',
                                fontWeight: 600,
                                lineHeight: '1.3',
                                color: '#4A5174',
                                fontSize: 12
                            }}> {' ' + data?.purchaseOrderNumber}</span>
                        </p>
                        <p><span style={{ "fontWeight": "bold" }}>Created Date #:</span><span
                            style={{
                                fontFamily: 'arial',
                                fontWeight: 600,
                                lineHeight: '1.3',
                                color: '#4A5174',
                                fontSize: 12
                            }}>{' ' + data?.createdDate}</span></p>
                    </div>
                </div>
                <div style={{ width: "100%", float: "left" }}>
                    <h3 style={{ "textAlign": "center", margin: "15px 0px" }}>ITEMS</h3>
                </div>
                <div style={{ width: "100%", float: "left" }}>
                    <table style={{ "width": "100%", float: "left", borderCollapse: "collapse", borderBottom: "2px solid #ececec" }}>
                        <thead>
                            <tr style={{ backgroundColor: "#eff4ff", textAlign: "left" }}>
                                <th style={{ padding: "15px 0px" }}>Model #</th>
                                <th style={{ padding: "15px 0px" }}>Description</th>
                                <th style={{ padding: "15px 0px" }}>Brand</th>
                                <th style={{ padding: "15px 0px" }}>Cost</th>
                                <th style={{ padding: "15px 0px" }}>Receiving Now</th>
                                <th style={{ padding: "15px 0px" }}>Comments</th>
                                <th style={{ padding: "15px 0px" }}>Bol</th>
                                <th style={{ padding: "15px 0px" }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reverseArray.map((value: any, index: any) => {
                                    return (
                                        <tr>
                                            <td style={{ padding: "15px 10px", color: '#4A5174' }}>{(value.model) ? value.model : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.description) ?value.description : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.brand) ? value.brand : "-"}</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.itemCost) ? value.itemCost : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value["receivingNow"]) ? value["receivingNow"] : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.comments) ?value.comments : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.bol) ? value.bol : "-" }</td>
                                            <td style={{ padding: "15px 0px", color: '#4A5174' }}>{(value.itemStatus) ? (value.itemStatus == "Completed") ? "Delivered" : (value.itemStatus == "ToDo")  ? "Scheduled" : (value.itemStatus == "Received") ? "Received"  : null   : "-" }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            {/*Hidden element for print ends here */}
        </>
    )
}